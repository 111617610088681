import Base from "@/modules/auth/Base.vue";

import UserForm from "@/modules/buyer/registrationForm/UserForm.vue";
import CompanyForm from "@/modules/buyer/registrationForm/CompanyForm.vue";
import CarList from "@/modules/admin/vehicles/list/List.vue";
import UserList from "@/modules/buyer/users/List.vue";
import SetPassword from "@/modules/buyer/users/SetPassword";
import VehiclesTable from "@/modules/superAdmin/VehiclesTable";
import { ifRoleAuthenticated } from "@/router/navigationGuards";

const routes = [
  {
    path: "/buyer",
    component: Base,
    children: [
      {
        path: "create",
        component: UserForm,
        name: "buyer.create",
      },
      {
        path: "company-create/:token",
        component: CompanyForm,
        name: "buyer.company-create",
      },
      {
        path: "car-list",
        name: "client.car-list",
        component: CarList,
        beforeEnter: (to, from, next) => {
          ifRoleAuthenticated(to, from, next, "ROLE_CLIENT");
        },
      },
      {
        path: "buyer-car-list",
        name: "buyer.car-list",
        component: CarList,
        beforeEnter: (to, from, next) => {
          ifRoleAuthenticated(to, from, next, ["ROLE_BUYER", "ROLE_WHOLESALE", "ROLE_RETAIL"]);
        },
      },
      {
        path: "users",
        name: "buyer.users",
        component: UserList,
        beforeEnter: (to, from, next) => {
          ifRoleAuthenticated(to, from, next, "ROLE_CLIENT");
        },
      },
      {
        path: "client/vehicles/table",
        name: "client.vehicles.table",
        component: VehiclesTable,
        beforeEnter: (to, from, next) => {
          ifRoleAuthenticated(to, from, next, "ROLE_CLIENT");
        },
      },
      {
        path: "user/confirm/:token",
        name: "buyer.user.confirm",
        component: SetPassword,
      },
    ],
  },
];

export default routes;
