var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6","lg":"4","xl":"4"}},[_c('b-button',{staticClass:"btn-lg shadow",attrs:{"variant":"purple","block":""},on:{"click":_vm.openModal}},[_c('b-row',{staticClass:"center-block mt-1"},[_c('img',{staticClass:"mr-3",staticStyle:{"max-width":"40px"},attrs:{"src":require("@/assets/img/icons/upload_photos_img.png"),"alt":"uploadPhotos"}}),_c('h3',[_vm._v("Upload Photos")])])],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-3 mb-3",attrs:{"sm":"12","md":"6","lg":"4","xl":"4"}},[_c('b-card',{staticClass:"shadow"},[_c('div',{staticClass:"mb-2 mt-1 text-center"},[_c('h5',[_vm._v(_vm._s(_vm.$t("photosUploadedManually")))])])]),_c('div',{staticStyle:{"width":"100%"},style:(_vm.vehicleHasPartsProps && !_vm.vehicleHasPartsProps.length
            ? 'height: 3rem;'
            : 'height: 33rem; overflow-y: scroll')},[_c('b-list-group',[(_vm.vehicleHasPartsProps && !_vm.vehicleHasPartsProps.length)?_c('b-list-group-item',[_c('div',[_vm._v(" Hey! Looks like you are the first person to take some pics ")])]):_vm._e(),_vm._l((_vm.vehicleHasPartsProps),function(p,i){return _c('b-list-group-item',{key:i},[_c('div',{staticClass:"vehicle-part-content d-flex justify-content-between"},[_c('div',{},[_c('img',{staticClass:"vehicle-part-img mr-3 img-thumbnail",attrs:{"width":"200","src":_vm.imgsNotFound.find(function (e) { return e === i; })
                      ? require('@/assets/img/image_not_found.png')
                      : p.photoThumbnail,"alt":p.part.name},on:{"click":function($event){return _vm.onInputImageToPreview(p.photo, p.part.name)},"error":function($event){return _vm.errorLoadImg(i)}}}),_c('p',{staticClass:"vehicle-part-name h5 d-flex align-items-center"},[_vm._v(" "+_vm._s(p.part.name)+" ")])]),_c('div',{staticClass:"vehicle-part-delete d-flex align-items-center"})])])})],2)],1)],1),_c('b-col',{staticClass:"mt-3 mb-3",attrs:{"sm":"12","md":"6","lg":"4","xl":"4"}},[_c('b-card',{staticClass:"shadow"},[_c('div',{staticClass:"mb-2 mt-1 text-center"},[_c('h5',[_vm._v("Photos uploaded by Condition Report")])])]),_c('div',{staticStyle:{"width":"100%"},style:(_vm.vehicleExternalImagesProps && !_vm.vehicleExternalImagesProps.length
            ? 'height: 3rem;'
            : 'height: 33rem; overflow-y: scroll')},[_c('b-list-group',[(
              _vm.vehicleExternalImagesProps && !_vm.vehicleExternalImagesProps.length
            )?_c('b-list-group-item',[_c('div',[_vm._v(" Hey! Looks like you are the first person to take some pics ")])]):_vm._e(),_vm._l((_vm.vehicleExternalImagesProps),function(p,i){return _c('b-list-group-item',{key:'external img' + i},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"d-flex flex-row"},[_c('img',{staticClass:"mr-3 img-thumbnail",attrs:{"width":"200","src":p.photo},on:{"click":function($event){return _vm.onInputImageToPreview(p.photo, null)}}})]),_c('div',{staticClass:"d-flex align-items-center"})])])})],2)],1)],1),_c('b-col',{staticClass:"mt-3",attrs:{"sm":"12","md":"6","lg":"4","xl":"4"}},[_c('b-card',{staticClass:"preview shadow text-center"},[_c('h5',[_vm._v("Preview")]),(
            !_vm.vehicleHasPartsProps.length && !_vm.vehicleExternalImagesProps.length
          )?_c('div',{staticClass:"mt-3"},[_c('span',[_vm._v(" Nothing selected to show ")])]):_c('div',[_c('b-row',[_c('b-col',[(_vm.imgError)?_c('img',{staticClass:"img-fluid img-thumbnail",attrs:{"src":_vm.imgError,"alt":""}}):_c('img',{staticClass:"mt-3 carousel-item active main-img img-thumbnail",attrs:{"width":"330","src":_vm.imageToPreview,"alt":"preview"},on:{"error":function($event){return _vm.errorLoadImg(null, true)}}})])],1),_c('b-row',[_c('b-col',[_c('h4',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.partNameToPreview))])])],1)],1)])],1),_c('UploadModal',{attrs:{"vehicleId":_vm.vehicleId}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }