<template>
  <div>
    <b-row class="mx-0">
      <b-col xs="12" md="12" lg="10" class="pr-0 pl-0 mx-0">
        <div>
          <vue-multiselect
            v-model="selected"
            label="name"
            track-by="name"
            open-direction="bottom"
            :options="roles"
            :multiple="false"
            :searchable="false"
            :internal-search="false"
            :clear-on-select="false"
            :close-on-select="false"
            :options-limit="300"
            :limit="3"
            :max-height="600"
            :show-no-results="true"
            :hide-selected="true"
            :tagPlaceholder="''"
            :selectLabel="''"
          >
            <template slot="tag" slot-scope="{ option, remove }"
              ><span class="custom__tag"
                ><span>{{ option.name }}</span
                ><span class="custom__remove" @click="remove(option)"
                  >❌</span
                ></span
              ></template
            >
            <template slot="clear" slot-scope="props">
              <div
                class="multiselect__clear"
                v-if="selected.length"
                @mousedown.prevent.stop="clearAll(props.search)"
              ></div>
            </template>
            <span slot="noResult"
              >Oops! No elements found. Consider changing the search
              query.</span
            >
          </vue-multiselect>
        </div>
      </b-col>
      <b-col md="12" lg="2" class="px-1 mx-0 text-center">
        <b-icon
          v-if="!isLoading"
          @click="changeRole()"
          icon="check-circle"
          font-scale="1.20"
          class="mr-1 mt-2 cursor-pointer"
          variant="success"
        >
        </b-icon>
        <b-spinner
          v-else
          label="Loading..."
          small
          variant="success"
        ></b-spinner>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { PUT_CHANGE_BUYER_ROLE } from "./actions";

export default {
  name: "RoleSelect",
  props: {
    refreshData: {
      type: Function,
      required: true,
    },
    roleId: {
      type: Number,
      required: false,
    },
    user: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selected: [],
      roles: [
        {
          id: 3,
          name: "Wholesale",
        },
        {
          id: 4,
          name: "Retail",
        },
      ],
      isLoading: false,
    };
  },

  created() {},
  mounted() {
    this.selectDefaultRole();
  },

  methods: {
    clearUserSelect() {
      this.selected = [];
    },
    changeRole() {
      this.isLoading = true;
      this.$store
        .dispatch(PUT_CHANGE_BUYER_ROLE, {
          id: this.user.id,
          roleId: this.selected.id,
        })
        .then(() => {
          this.$root.$bvToast.toast("Role changed successfully", {
            title: "Success",
            variant: "success",
          });
          this.clearUserSelect();
          this.refreshData();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    selectDefaultRole() {
      if (this.roleId) {
        this.selected = this.roles.find((role) => role.id === this.roleId);
      }
    },
  },
};
</script>

<style></style>
