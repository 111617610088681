<template>
  <b-form>
    <b-form-group label="Date">
      <b-form-input
        type="date"
        @change="setValue('date', $event)"
        :state="validateState('date')"
        v-model.trim="$v.form.date.$model"
        aria-describedby="input-date-live-feedback"
      />
      <b-form-invalid-feedback id="input-date-live-feedback">
        This is a required field and must at least 2 characters.
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group label="Number">
      <b-form-input
        type="number"
        @change="setValue('number', $event)"
        :state="validateState('number')"
        v-model.trim="$v.form.number.$model"
        aria-describedby="input-number-live-feedback"
      />
      <b-form-invalid-feedback id="input-number-live-feedback">
        This is a required field and no decimals.
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group label="Amount">
      <Money
        class="form-control"
        @change="setValue('amount', $event)"
        :state="validateState('amount')"
        v-model.trim="$v.form.amount.$model"
        v-bind="money"
        aria-describedby="input-amount-live-feedback"
      />
      <b-form-invalid-feedback id="input-amount-live-feedback">
        This is a required field.
      </b-form-invalid-feedback>
    </b-form-group>
    <div class="text-right">
      <b-button
        :disabled="$v.form.$invalid || saveCheck === 'fetching'"
        variant="primary"
        @click="addCheck()"
      >
        <b-spinner v-if="saveCheck === 'fetching'" variant="white" small />
        <span v-else>{{ $t("submit") }}</span>
      </b-button>
    </div>
  </b-form>
</template>

<script>
import { mapState } from "vuex";
import { POST_VEHICLE_CHECK } from "../actions";
import { required, numeric } from "vuelidate/lib/validators";
import { Money } from "v-money";
import { superAdminMixin } from "../../mixins";
export default {
  props: ["vehicle"],
  mixins: [superAdminMixin],
  components: { Money },
  computed: {
    ...mapState({
      saveCheck: (state) =>
        state.superAdminStore.componentStore.saveCheck.status,
    }),
  },
  data() {
    return {
      form: {
        date: null,
        number: null,
        amount: 0,
        vehicleId: null,
      },
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "USD$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
    };
  },
  validations: {
    form: {
      date: {
        required,
      },
      number: {
        required,
        numeric,
      },
      amount: {
        required,
        isZero: (value) => (value === 0.0 ? false : true),
      },
    },
  },
  methods: {
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form.date = null;
      this.form.number = null;
      this.form.amount = 0;
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    addCheck() {
      if (this.$v.form.$invalid) return;
      this.form.vehicleId = this.vehicle.id;
      this.$store
        .dispatch(POST_VEHICLE_CHECK, this.form)
        .then(() => {
          this.resetForm();
          this.getChecks(true, this.vehicle);
          this.toastMessage("Vehicle checked", "Success", "success");
          this.getVehiclesInfo();
        })
        .catch((error) => {
          this.toastMessage(error, "Warning", "warning");
        });
    },
  },
};
</script>

<style></style>
