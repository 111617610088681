<template>
  <div>
    <Nav @set-status="setType"></Nav>

    <div class="container-fluid mt-1">
      <b-row class="mb-4 mr-1 ml-1">
        <b-col
          xs="12"
          sm="12"
          md="12"
          lg="4"
          xl="4"
          class="d-flex justify-content-center mt-1"
        >
        </b-col>

        <b-col xs="12" sm="12" md="12" lg="4" xl="4" class="mt-1">
          <b-input-group
            size="sm"
            class="shadows rounded-right rounded-left align-middle"
          >
            <b-form-input
              id="filterInput"
              v-model="filter"
              class="filter-input rounded-right rounded-left"
              type="search"
              @change="getData($event)"
            />
            <b-input-group-append class="rounded-right rounded-left">
              <b-icon
                icon="search"
                variant="primary"
                class="h5 m-2 align-middle"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>

        <b-col xs="12" sm="12" md="12" lg="4" xl="4" class="text-center mt-1">
          <b-button variant="success" @click="showModal()">
            <b-icon icon="plus" class="mt-1" />
          </b-button>
        </b-col>
      </b-row>
      <b-table
        show-empty
        bordered
        small
        stacked="md"
        head-variant="light"
        :items="users.data ? users.data.data : []"
        :fields="fields"
        tbody-tr-class=""
        :busy="users.status == 'fetching'"
        class=""
      >
        <template #empty>
          <div class="text-center">
            <h6>{{ $t("thereAreNoRecordsToShow") }}</h6>
          </div>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" variant="yellow" />
          </div>
        </template>

        <template v-slot:cell(auctionAccessNumber)="{ item }">
          <div v-if="item.auctionAccessNumber">
            {{ item.auctionAccessNumber }}
            <b-button
              v-if="item.auctionAccessNumber"
              variant="none"
              v-clipboard:copy="
                item.auctionAccessNumber ? item.auctionAccessNumber : ''
              "
              v-clipboard:success="copyAuctionNumber"
            >
              <b-icon v-if="auctionAccessNumberCopied" icon="clipboard-check" />
              <b-icon v-else icon="clipboard" />
            </b-button>
          </div>
        </template>
        <template v-slot:cell(company)="{ item }">
          <span v-if="item.company">{{ item.company.companyName }}</span>
          <div v-else>
            <CompanySelect
              :refreshData="getData"
              :user="item"
              :companyId="item.companiesId"
            />
          </div>
        </template>
        <template v-slot:cell(role)="{ item }">
          <span v-if="item.role">{{ item.role.name }}</span>
          <div v-else>
            <RoleSelect
              :refreshData="getData"
              :roleId="item.roles[0].id"
              :user="item"
            />
          </div>
        </template>
        <template v-slot:cell(actions)="{ item }">
          <div class="text-center">
            <b-form-group>
              <b-icon
                v-b-tooltip.hover
                title="Delete"
                :icon="item.status == 0 ? 'toggle-off' : 'toggle-on'"
                class="h5 m-2 cursor-pointer"
                @click="
                  item.status == 0 ? activateItem(item) : deleteItem(item)
                "
              />
              <b-icon
                v-if="type == 'preRegistration' && !isEmailLoading.status"
                v-b-tooltip.hover
                title="Send email"
                icon="envelope"
                class="h5 m-2 cursor-pointer"
                @click="sendEmailCompanyForm(item)"
              />
              <b-spinner
                v-if="type == 'preRegistration' && isEmailLoading.id == item.id"
                small
                variant="primary"
              />

              <b-icon
                v-if="
                  type == 'inReview' ||
                  type == 'active' ||
                  type == 'preRegistration'
                "
                v-b-tooltip.hover
                title="Details"
                icon="eye"
                class="h5 m-2 cursor-pointer"
                @click="showModal(item)"
              />
              <b-icon
                v-if="type == 'preRegistration' || type == 'inReview'"
                v-b-tooltip.hover
                title="Reject"
                icon="x"
                class="h5 m-2 cursor-pointer"
                @click="rejectUser(item)"
              />
            </b-form-group>
          </div>
        </template>
      </b-table>
      <b-row>
        <b-col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="d-flex justify-content-end"
        >
          <b-pagination
            :value="users.data ? users.data.currentPage : 1"
            :total-rows="users.data ? users.data.total : 0"
            :per-page="users.data ? users.data.perPage : 0"
            class="mb-2"
            @change="changePage($event)"
          />
        </b-col>
      </b-row>
    </div>

    <b-modal
      id="company-modal"
      ref="company-modal"
      size="xl"
      hide-footer
      body-class="pl-0 pr-0"
      ok-disabled
    >
      <ClientForm
        :refresh-data="getData"
        :hide-modal="hideModal"
        :userItem="userItem"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  GET_USER_TO_ASSIGN_COMPANY,
  POST_SEND_EMAIL_COMPANY_FORM,
  PUT_REJECT_COMPANY,
} from "./actions";
import CompanySelect from "./CompanySelect.vue";
import Nav from "./Nav.vue";
import ClientForm from "./ClientForm.vue";
import { generalMixin } from "../../mixin";
import { DELETE_USER_BUYER, PUT_USER_BUYER } from "../../buyer/users/actions";
import { GET_COMPANIES_LIST } from "./actions";
import RoleSelect from "./RoleSelect.vue";
export default {
  components: {
    CompanySelect,
    Nav,
    ClientForm,
    RoleSelect,
  },
  mixins: [generalMixin],
  computed: {
    ...mapState({
      users: (state) => state.adminStore.assignCompany.users || [],
    }),
  },
  name: "AssignCompanyTable",
  data() {
    return {
      fields: [
        { key: "firstName", label: this.$t("firstName") },
        { key: "lastName", label: this.$t("lastName") },
        { key: "mobileNumber", label: this.$t("mobileNumber") },
        { key: "officePhoneNumber", label: this.$t("officePhoneNumber") },
        { key: "email", label: this.$t("officePhoneNumber") },
        { key: "auctionAccessNumber", label: this.$t("auctionAccess") },
        { key: "company", label: this.$t("company") },
        { key: "role", label: this.$t("role") },

        {
          key: "createdAt",
          label: this.$t("createdAt"),
          formatter: (date) => this.$options.filters.formatDate(date),
        },
        { key: "actions", label: "Actions" },
      ],
      type: "",
      filter: null,
      userItem: null,

      auctionAccessNumberCopied: false,
      isEmailLoading: {
        id: null,
        status: false,
      },
    };
  },
  created() {
    this.getData();
    this.getCompanies();
  },
  methods: {
    getData(filter = "") {
      /*       this.getUsersPermission();
       */ this.$store
        .dispatch(GET_USER_TO_ASSIGN_COMPANY, {
          filter,
          type: this.type == "" ? "preRegistration" : this.type,
        })
        .then(() => {})
        .catch(() => {});
    },
    setType(type) {
      this.type = type;
      this.getData();
    },
    changePage(page, filter = "") {
      const payload = {
        type: this.type,
        search: filter,
        page: page,
      };

      this.$store
        .dispatch(GET_USER_TO_ASSIGN_COMPANY, payload)
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    disableItem(item) {
      console.log(item);
    },
    showModal(item = null) {
      if (item) {
        this.userItem = this.formatDetails(item);
      }
      this.$bvModal.show("company-modal");
    },
    hideModal() {
      this.$bvModal.hide("company-modal");
    },
    async deleteItem(item) {
      const confirm = await this.showConfirmDeleteBox();
      if (!confirm) return;
      const payload = {
        id: item.id,
        status: 0,
      };
      this.$store
        .dispatch(DELETE_USER_BUYER, payload)
        .then(() => {
          this.makeToast(
            this.$("success"),
            "User updated successfully",
            "success"
          );
          this.getData();
        })
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    activateItem(item) {
      let payload = {
        id: item.id,
        status: 1,
      };
      this.$store
        .dispatch(PUT_USER_BUYER, payload)
        .then(() => {
          this.makeToast(
            this.$t("success"),
            "User updated successfully",
            "success"
          );
          this.getData();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Could not update user, try again later", {
            title: this.$t("error"),
            variant: "warning",
          });
        });
    },
    getUsersPermission() {
      this.$store
        .dispatch(GET_COMPANIES_LIST, {})
        .then(() => {})
        .catch(() => {});
    },
    formatDetails(item) {
      let user = {
        id: item.id,
        firstName: item.firstName,
        lastName: item.lastName,
        email: item.email,

        mobileNumber: item.mobileNumber,
      };
      return {
        id: item.id,
        user,
        companyName: item.company?.companyName,
        commercialName: item.company?.commercialName,
        companyId: item.company?.id,
        auctionAccessNumber: item.auctionAccessNumber,
        officePhoneNumber: item.officePhoneNumber,
        preRegisterCompany: item.preRegisterCompany,
      };
    },
    copyAuctionNumber(number) {
      this.auctionAccessNumberCopied = number;
    },
    rejectUser(user) {
      this.$store
        .dispatch(PUT_REJECT_COMPANY, {
          id: user.id,
        })
        .then(() => {
          this.makeToast(
            this.$t("success"),
            "User rejected successfully",
            "success"
          );
          this.getData();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Could not reject user, try again later", {
            title: this.$t("error"),
            variant: "warning",
          });
        });
    },
    sendEmailCompanyForm(user) {
      this.isEmailLoading = {
        id: user.id,
        status: true,
      };
      this.$store
        .dispatch(POST_SEND_EMAIL_COMPANY_FORM, { email: user.email })
        .then(() => {
          this.makeToast(
            this.$t("success"),
            "Email sent successfully",
            "success"
          );
        })
        .catch(() => {
          this.$root.$bvToast.toast("Could not send email, try again later", {
            title: this.$t("error"),
            variant: "warning",
          });
        })
        .finally(() => {
          this.isEmailLoading = {
            id: null,
            status: false,
          };
        });
    },
    getCompanies() {
      this.$store
        .dispatch("GET_COMPANIES_LIST")
        .then(() => {})
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped></style>
