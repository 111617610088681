<template>
  <div class="mt-4 mb-1">
    <b-row class="mb-4 mr-1 ml-1">
      <b-col
        xs="12"
        sm="12"
        md="12"
        lg="4"
        xl="4"
        class="d-flex justify-content-center"
      >
        <slot name="leftSide" />
      </b-col>

      <b-col xs="12" sm="12" md="12" lg="4" xl="4" class="">
        <b-input-group
          size="sm"
          class="shadows rounded-right rounded-left align-middle bg-primary mb-2"
        >
          <b-form-input
            class="filter-input rounded-right rounded-left"
            type="search"
            id="filterInput"
            @change="search($event)"
          />
          <b-input-group-append class="rounded-right rounded-left">
            <b-icon icon="search" variant="pink" class="h5 m-2 align-middle" />
          </b-input-group-append>
        </b-input-group>
      </b-col>

      <b-col
        xs="12"
        sm="12"
        md="12"
        lg="4"
        xl="4"
        class="text-center"
        :class="{ 'mt-2': isMobile }"
      >
        <b-button variant="success" @click="showUploadVehicleModal()">
          <b-icon icon="plus" class="mt-1" />
        </b-button>
      </b-col>
    </b-row>

    <b-modal
      id="uploadVehicleModal"
      title="Upload vehicle"
      size="lg"
      hide-footer
    >
      <div class="upload-vehicle">
        <SearchVehicleByVin
          :showProgressBar="false"
          :showUploadVehiclesFile="false"
          :redirectToTable="false"
          :closeUploadVehicleModal="true"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { superAdminMixin } from "../mixins";
import SearchVehicleByVin from "@/modules/admin/vehicles/registry/SearchVehicleByVin";
export default {
  props: ["isMobile"],
  mixins: [superAdminMixin],
  components: {
    SearchVehicleByVin,
  },
  methods: {
    showUploadVehicleModal() {
      this.$bvModal.show("uploadVehicleModal");
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-input {
  background-color: #12144a;
  opacity: 1;
  color: white;
  border: none;
}
.form-control:focus {
  background-color: #12144a;
  color: white;
  border-color: #12144a;
}
.shadows {
  box-shadow: 0 7px 7px 0 rgba(0, 0, 0, 0.5);
}
.upload-vehicle {
  margin-top: -4rem;
}
@media (min-width: 600px) {
  .upload-vehicle {
    margin-left: 32%;
  }
}
</style>
