<template>
  <div class="layout-container">
    <NavBar />
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import NavBar from "./NavBar";
import Footer from "./Footer";
export default {
  name: "Default",
  components: { NavBar, Footer },
};
</script>

<style>
/* Aplica un box-sizing global */
* {
  box-sizing: border-box;
}

/* Configura un flex container para el diseño principal */
.layout-container {
  margin: 0;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Al menos 100% de la altura de la ventana */
}

/* Aplica estilos al NavBar */
NavBar {
  background-color: #333;
  color: white;
  text-align: center;
}

/* Aplica estilos al router-view (contenido principal) */
router-view {
  flex-grow: 1; /* Permite que el contenido principal crezca y llene el espacio disponible */
}

/* Aplica estilos al Footer */
Footer {
  color: white;
  text-align: center;
  margin-top: auto; /* Empuja el footer hacia abajo, ocupando el espacio restante */
  flex-shrink: 0;
}
</style>
