<template>
  <b-sidebar lazy :width="widthSidebar" :visible="offerSideBar.show" right>
    <template #header="{ hide }">
      <div class="ml-3">
        <h5>
          <b-icon
            icon="x-square"
            @click="hideSideBar(hide)"
            style="cursor: pointer"
          />
        </h5>
      </div>
    </template>
    <!-- Vehicle Information -->
    <b-container fluid class="mt-2" v-if="offerSideBar.data">
      <b-row class="ml-1">
        <!--      <b-col v-if="userIsAdmin || userIsAgent">
          <b-row>
            <b-col>
              <small class="text-muted"> Cost </small>
            </b-col>
          </b-row>
          <i class="offerPrices">
            {{ offerSideBar.data.cost | currency }}
          </i>
        </b-col> -->
        <b-col>
          <b-row>
            <b-col>
              <small class="text-muted"> Selling price </small>
            </b-col>
          </b-row>
          <i class="offerPrices">
            {{ offerSideBar.data.price | currency }}
          </i>
        </b-col>
      </b-row>
      <!--       <b-row class="ml-3 mt-2">
        Cost:
        <i class="vehicleInfo">
          {{ costCalculated | currency }}
        </i>
      </b-row> -->
      <b-row class="ml-1 mt-2">
        <b-col v-if="offerSideBar.data.stockNumber">
          {{ $t("stock") }}:
          <i class="vehicleInfo">
            {{ offerSideBar.data.stockNumber }}
          </i>
        </b-col>
        <b-col v-if="offerSideBar.data.make">
          {{ $t("make") }}:
          <i class="vehicleInfo">
            {{ offerSideBar.data.make }}
          </i>
        </b-col>
      </b-row>
      <b-row class="mt-2 ml-1">
        <b-col v-if="offerSideBar.data.model">
          {{ $t("model") }}:
          <i class="vehicleInfo">
            {{ offerSideBar.data.model }}
          </i>
        </b-col>
        <b-col v-if="offerSideBar.data.year">
          {{ $t("year") }}:
          <i class="vehicleInfo">
            {{ offerSideBar.data.year }}
          </i>
        </b-col>
      </b-row>
      <b-row class="mt-2 ml-1">
        <b-col v-if="offerSideBar.data.trim">
          {{ $t("trim") }}:
          <i class="vehicleInfo">
            {{ offerSideBar.data.trim }}
          </i>
        </b-col>
        <b-col v-if="offerSideBar.data.options">
          {{ $t("options") }}:
          <i class="vehicleInfo">
            {{ offerSideBar.data.options }}
          </i>
        </b-col>
      </b-row>
      <b-row class="mt-2 ml-1">
        <b-col v-if="offerSideBar.data.miles">
          {{ $t("mileage") }}:
          <i class="vehicleInfo">
            {{ offerSideBar.data.miles }}
          </i>
        </b-col>
      </b-row>
      <b-row class="mt-2 mb-3 ml-1">
        <b-col v-if="offerSideBar.data.location">
          {{ $t("location") }}:

          <i class="vehicleInfo">
            {{ offerSideBar.data.location }}
          </i>
        </b-col>
        <!--      <b-col v-if="offerSideBar.data.releaseDate">
          Release date:

          <i class="vehicleInfo">
            {{ offerSideBar.data.releaseDate }}
          </i>
        </b-col> -->
      </b-row>
      <b-row class="mt-2 mb-3 ml-1">
        <!--    <b-col
          v-if="offerSideBar.data.crUrl"
          @click="openCrModal()"
          class="cursor-pointer"
        >
          View Condition Report
        </b-col> -->
        <b-col
          v-if="offerSideBar.data.vehicleId"
          @click="openPublicView()"
          class="cursor-pointer"
        >
          View vehicle
        </b-col>
      </b-row>
      <!-- List offers -->
      <div v-for="offer in vehicleOffers" :key="offer.id" class="mb-2">
        <OfferCard :vehicleId="offerSideBar.data.vehicleId" :offer="offer" />
      </div>
    </b-container>
    <CRPageModal
      v-if="offerSideBar.data"
      :id="this.offerSideBar.data.id"
      :crUrl="this.offerSideBar.data.crUrl"
    />
  </b-sidebar>
</template>

<script>
import { mapState } from "vuex";
import OfferCard from "../OfferCard";
import { generalMixin } from "@/modules/mixin";
import { SET_VEHICLE_FILTER_ACTION } from "../../list/actions";
import { vehicleOffersMixins } from "../mixins";
import { listVehiclesMixin } from "../../list/mixins";
import CRPageModal from "../../list/components/CRPageModal";

export default {
  components: { OfferCard, CRPageModal },
  mixins: [generalMixin, vehicleOffersMixins, listVehiclesMixin],
  computed: {
    widthSidebar() {
      return this.$vssWidth > 1499
        ? "32%"
        : this.$vssWidth <= 576
        ? "100%"
        : "50%";
    },
    ...mapState({
      offerSideBar: (state) =>
        state.adminStore.vehicles.offersStore.offerSideBar,
      vehicleOffers: (state) =>
        state.adminStore.vehicles.offersStore.offersByVehicle.data.data,
    }),

    costCalculated() {
      /*    let cost = this.offerSideBar.data.cost;
      if (this.offerSideBar.data.fx) {
        if (this.offerSideBar.data.cost === 0) {
          return cost;
        } else {
          return (cost + 2500) / this.offerSideBar.data.fx;
        }
      } */
      return this.vehicle.cost;
    },
  },
  methods: {
    searchVehiclesByTabMenu() {
      switch (this.filterAction) {
        case "GET_VEHICLES":
          this.getVehicles();
          break;
        case "GET_ACTIVE_OFFERS":
          this.getActiveOffers();
          break;
        case "GET_EXPIRED_OFFERS":
          this.getExpiredOffers();
          break;
        case "GET_PURCHASES":
          this.getPurchases();
          break;
        case "GET_VEHICLES_OFFERS":
          this.$store.dispatch(this.filterAction);
          break;
      }
    },
    hideSideBar(hide) {
      hide();
      if (this.$route.name === "admin.vehicles.offers") {
        this.$store.commit(
          SET_VEHICLE_FILTER_ACTION,
          "GET_VEHICLES_WITH_OFFERS"
        );
        this.getOffers(true);
      } else if (
        this.$route.name === "buyer.car-list" ||
        this.$route.name === "client.car-list"
      ) {
        this.searchVehiclesByTabMenu();
      }
    },
    openCrModal() {
      this.$bvModal.show("CR-Page-Modal" + this.offerSideBar.data.id);
    },
    openPublicView() {
      const link = `https://dashboard.transcanauto.com/#/public/vehicle/${this.idToBase64(
        this.offerSideBar.data.vehicleId
      )}`;
      window.open(link, "_blank");
    },
    idToBase64(id) {
      return btoa(id);
    },
  },
};
</script>

<style scoped lang="scss">
.userOffer {
  background-color: #f5f5f5;
}
.offerPrices {
  font-size: 22px;
}
@media screen and (max-width: 300px) {
  .offerPrices {
    font-size: 17px;
  }
  .vehicleInfo {
    font-size: 15px;
  }
}
</style>
