<template>
  <footer>
    <div class="text-center footer bg-primary">
      <p class="text-white pb-3 pt-4 mt-0 mb-0 container">
        Copyright 2021 Proleasing Powered by
        <a href="https://propulsoft.ca/">
          <strong class="text-white">Propulsoft.ca</strong>
        </a>
      </p>
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer",
};
</script>
