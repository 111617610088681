``
<template>
  <div>
    <b-row class="mt-2">
      <b-col v-if="offerChain && offerChain.length === 0" class="text-center">
        No Counter-Offers
      </b-col>
      <b-col v-else class="counter-offer-history" id="counter-offers">
        <div
          v-for="(offer, index) in offerChain"
          :key="'offer' + index"
          class=""
        >
          <!-- Offer user -->
          <b-row
            v-if="offer.role === 'ROLE_CLIENT' || offer.role === 'ROLE_BUYER'"
          >
            <b-col class="buyer-price-color mb-2">
              <b-row>
                <b-col>
                  <small class="register-hour"> {{ buyerName }} </small>
                </b-col>
              </b-row>
              {{ offer.offerPrice | currency }}
              <br />
              <span class="register-hour">
                {{ offer.createdAt | formatDateTime }}
              </span>
            </b-col>
          </b-row>
          <!-- Offer admin -->
          <b-row
            v-if="
              offer.role === 'ROLE_ADMIN' ||
              offer.role === 'ROLE_AGENT' ||
              offer.role === 'ROLE_SUPER_ADMIN'
            "
          >
            <b-col class="admin-price-color text-right mb-2">
              <b-row>
                <b-col>
                  <small class="register-hour"> Proleasing </small>
                </b-col>
              </b-row>
              {{ offer.offerPrice | currency }}
              <br />
              <span class="register-hour">
                {{ offer.createdAt | formatDateTime }}
              </span>
              <hr />
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { vehicleOffersMixins } from "../mixins";
import { GET_OFFERS_BY_USER, GET_ACTIVE_OFFERS } from "../actions";

export default {
  mixins: [vehicleOffersMixins],
  props: [
    "offerPrice",
    "offerChain",
    "userIsAdmin",
    "userIsBuyer",
    "userIsAgent",
    "userIsClient",
    "buyerName",
  ],
  computed: {
    ...mapState({
      offerChainStatus: (state) =>
        state.adminStore.vehicles.offersStore.offerChain.status,
      user: (state) => state.auth.loginStore.profile.data.user,
    }),
  },
  methods: {
    getActiveOffers() {
      const payload = {
        action: GET_ACTIVE_OFFERS,
        status: 1,
      };
      this.$store.dispatch(GET_OFFERS_BY_USER, payload);
    },
  },
};
</script>
<style lang="scss">
.counter-offer-history {
  background-color: #f8f9f9;
  font-size: 20px;

  h6 {
    color: #35a4dd;
  }

  .admin-price-color {
    color: #44718e;
  }

  .buyer-price-color {
    color: #009688;
  }
  .register-hour {
    font-size: 12px;
    color: #7b7d7d;
  }
}
</style>
