<template>
  <div>
    <VehicleCardGallery
      class=""
      :images="vehicleImages"
      :showThumbnail="false"
      :typeList="'list'"
      style="max-width: 15rem !important"
    />
  </div>
</template>

<script>
import VehicleCardGallery from "./VehicleCard/VehicleCardGallery.vue";
import IMAGES_PARTS from "./VehicleCard/images-parts";
export default {
  name: "VehiclesListViewImage",
  components: {
    VehicleCardGallery,
  },
  props: {
    vehicle: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      vehicleImages: [],
      BASE_URL: "https://proleasing.nyc3.cdn.digitaloceanspaces.com/Prod",
      isLoadingImagesFromSpace: false,
    };
  },
  computed: {
    baseUrl() {
      return `${this.BASE_URL}/${this.vehicle?.year}/${this.vehicle?.make}/${this.vehicle?.model2}/${this.vehicle?.color}`;
    },
  },
  methods: {
    formatImagesFromSpace() {
      this.isLoadingImagesFromSpace = true;
      let images = [];
      let baseUrl = this.baseUrl;
      let promises = [];
      IMAGES_PARTS.forEach((part) => {
        let img = new Image();
        let promise = new Promise((resolve) => {
          img.onload = () => {
            images.push({
              id: `${baseUrl}/${part}.jpeg`,
              src: `${baseUrl}/${part}.jpeg`,
              largeViewSrc: `${baseUrl}/${part}.jpeg`,
              thumbnail: `${baseUrl}/${part}.jpeg`,
            });
            resolve();
          };
          img.onerror = () => {
            resolve();
          };
        });
        promises.push(promise);
        img.src = `${baseUrl}/${part}.jpeg`;
      });
      Promise.all(promises).then(() => {
        if (images.length === 0) {
          images.push({
            id: "proleasing-logo.png",
            src: "proleasing-logo.png",
            largeViewSrc: "proleasing-logo.png",
            thumbnail: "proleasing-logo.png",
          });
        }
        if (images.find((img) => img.src !== "proleasing-logo.png")) {
          this.vehicleImages = images.filter(
            (img) => img.src !== "proleasing-logo.png"
          );
        } else {
          this.vehicleImages = images;
        }
        this.isLoadingImagesFromSpace = false;
      });
    },
    formatManualImages() {
      this.vehicleImages = this.vehicle.vehicleHasParts.map((part) => {
        return {
          id: part.id,
          src: part.photo,
          largeViewSrc: part.photo,
          thumbnail: part.photoThumbnail,
        };
      });
    },
  },
  mounted() {
    if (this.vehicle.vehicleHasParts.length > 0) {
      this.formatManualImages();
    } else {
      this.formatImagesFromSpace();
    }
  },
};
</script>

<style></style>
