/** @format */

const CONFIG = {
  ENV: process.env.NODE_ENV,
  isDev() {
    return CONFIG.ENV == "development";
  },
  isProd() {
    return CONFIG.ENV == "production";
  },
  isStaging() {
    return CONFIG.ENV == "staging";
  },
  isLocal() {
    return CONFIG.ENV == "local";
  },
  baseURL() {
    if (CONFIG.isDev()) {
      // Change me for development url
      return "https://api-proleasing-dev.propulsoft.dev/market";
    } else if (CONFIG.isProd()) {
      // Change me for production url
      return "https://api-proleasing-dev.propulsoft.dev/market";
    } else if (CONFIG.isStaging()) {
      // Change me for staging url
      return "";
    }
  },
  frontEndURL() {
    if (CONFIG.isDev()) {
      return "https://canamcar.com";
    } else if (CONFIG.isProd()) {
      // Change me for production url
      return "https://canamcar.com";
    } else if (CONFIG.isStaging()) {
      // Change me for staging url
      return "";
    } else if (CONFIG.isLocal()) {
      // Change me for staging url
      return "localhost:8080";
    }
  },
};

export default CONFIG;
