<template>
  <div>
    <!-- Upload photos -->
    <b-row>
      <b-col sm="12" md="6" lg="4" xl="4">
        <b-button
          @click="openModal"
          class="btn-lg shadow"
          variant="purple"
          block
        >
          <b-row class="center-block mt-1">
            <img
              src="@/assets/img/icons/upload_photos_img.png"
              alt="uploadPhotos"
              style="max-width: 40px"
              class="mr-3"
            />
            <h3>Upload Photos</h3>
          </b-row>
        </b-button>
      </b-col>
    </b-row>
    <!-- Photos list -->
    <b-row>
      <!-- Internal photos -->
      <b-col class="mt-3 mb-3" sm="12" md="6" lg="4" xl="4">
        <b-card class="shadow">
          <div class="mb-2 mt-1 text-center">
            <h5>{{ $t("photosUploadedManually") }}</h5>
          </div>
        </b-card>
        <div
          style="width: 100%"
          :style="
            vehicleHasPartsProps && !vehicleHasPartsProps.length
              ? 'height: 3rem;'
              : 'height: 33rem; overflow-y: scroll'
          "
        >
          <b-list-group>
            <b-list-group-item
              v-if="vehicleHasPartsProps && !vehicleHasPartsProps.length"
            >
              <div>
                Hey! Looks like you are the first person to take some pics
              </div>
            </b-list-group-item>
            <b-list-group-item v-for="(p, i) in vehicleHasPartsProps" :key="i">
              <div class="vehicle-part-content d-flex justify-content-between">
                <div class="">
                  <img
                    width="200"
                    :src="
                      imgsNotFound.find((e) => e === i)
                        ? require('@/assets/img/image_not_found.png')
                        : p.photoThumbnail
                    "
                    :alt="p.part.name"
                    class="vehicle-part-img mr-3 img-thumbnail"
                    @click="onInputImageToPreview(p.photo, p.part.name)"
                    @error="errorLoadImg(i)"
                  />

                  <p class="vehicle-part-name h5 d-flex align-items-center">
                    {{ p.part.name }}
                  </p>
                </div>
                <div class="vehicle-part-delete d-flex align-items-center">
                  <!--    <b-button
                    @click="deletePhoto(p.id)"
                    :disabled="deletePhotosStatus === 'fetching'"
                    variant="light"
                  >
                    <b-icon variant="secondary" scale="2" icon="x" />
                  </b-button> -->
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </div>
      </b-col>
      <!-- External photos -->
      <b-col class="mt-3 mb-3" sm="12" md="6" lg="4" xl="4">
        <b-card class="shadow">
          <div class="mb-2 mt-1 text-center">
            <h5>Photos uploaded by Condition Report</h5>
          </div>
        </b-card>
        <div
          style="width: 100%"
          :style="
            vehicleExternalImagesProps && !vehicleExternalImagesProps.length
              ? 'height: 3rem;'
              : 'height: 33rem; overflow-y: scroll'
          "
        >
          <b-list-group>
            <b-list-group-item
              v-if="
                vehicleExternalImagesProps && !vehicleExternalImagesProps.length
              "
            >
              <div>
                Hey! Looks like you are the first person to take some pics
              </div>
            </b-list-group-item>
            <!-- External images -->
            <b-list-group-item
              v-for="(p, i) in vehicleExternalImagesProps"
              :key="'external img' + i"
            >
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-row">
                  <img
                    width="200"
                    :src="p.photo"
                    class="mr-3 img-thumbnail"
                    @click="onInputImageToPreview(p.photo, null)"
                  />
                </div>
                <div class="d-flex align-items-center">
                  <!--   <b-button
                    @click="deleteExternalPhoto(p.id)"
                    :disabled="deletePhotosStatus === 'fetching'"
                    variant="light"
                  >
                    <b-icon variant="secondary" scale="2" icon="x" />
                  </b-button> -->
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </div>
      </b-col>
      <b-col class="mt-3" sm="12" md="6" lg="4" xl="4">
        <b-card class="preview shadow text-center">
          <h5>Preview</h5>
          <div
            v-if="
              !vehicleHasPartsProps.length && !vehicleExternalImagesProps.length
            "
            class="mt-3"
          >
            <span> Nothing selected to show </span>
          </div>
          <div v-else>
            <b-row>
              <b-col>
                <img
                  v-if="imgError"
                  v-bind:src="imgError"
                  class="img-fluid img-thumbnail"
                  alt=""
                />
                <img
                  v-else
                  width="330"
                  class="mt-3 carousel-item active main-img img-thumbnail"
                  v-bind:src="imageToPreview"
                  alt="preview"
                  @error="errorLoadImg(null, true)"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <h4 class="mt-3">{{ partNameToPreview }}</h4>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
      <UploadModal :vehicleId="vehicleId" />
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import UploadModal from "./UploadModal";
import {
  GET_VEHICLE_PARTS,
  DELETE_PHOTO,
  DELETE_EXTERNAL_PHOTO,
} from "./actions";
import { registryVehicleMixin } from "../mixins";
export default {
  props: ["vehicleId", "vehicleHasPartsProps", "vehicleExternalImagesProps"],
  components: { UploadModal },
  mixins: [registryVehicleMixin],
  data() {
    return {
      imgError: null,
      imgsNotFound: [],
      imageToPreview: null,
      partNameToPreview: "",
    };
  },
  computed: {
    ...mapState({
      deletePhotosStatus: (state) =>
        state.adminStore.vehicles.carRegistry.uploadVehiclePhotosStore
          .deletePhoto.status,
    }),
  },
  methods: {
    openModal() {
      this.$store
        .dispatch(GET_VEHICLE_PARTS)
        .then(() => {
          this.$bvModal.show("modal-add-pic");
        })
        .catch(() => {
          this.$root.$bvToast.toast("The Vehicle parts could not be obtained", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    onInputImageToPreview(url, partName) {
      this.imgError = null;
      this.imageToPreview = url;
      this.partNameToPreview = partName;
    },
    showConfirmDeleteBox() {
      return this.$bvModal.msgBoxConfirm("Please Confirm", {
        title: "Please confirm that you want to delete the item.",
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: "YES",
        cancelTitle: "NO",
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true,
      });
    },
    photoDeleteSuccessMessage() {
      this.$root.$bvToast.toast("Photo deleted successfully", {
        title: "Success",
        variant: "success",
      });
    },
    photoDeleteErrorMessage() {
      this.$root.$bvToast.toast("The photo could not be deleted", {
        title: "Warning",
        variant: "warning",
      });
    },
    async deletePhoto(photoId) {
      const value = await this.showConfirmDeleteBox();
      if (!value) {
        return;
      } else {
        this.$store
          .dispatch(DELETE_PHOTO, photoId)
          .then(() => {
            this.getVehicleByVin();
            this.photoDeleteSuccessMessage();
          })
          .catch(() => {
            this.photoDeleteErrorMessage();
          });
      }
    },
    async deleteExternalPhoto(photoId) {
      const value = await this.showConfirmDeleteBox();
      if (!value) {
        return;
      } else {
        this.$store
          .dispatch(DELETE_EXTERNAL_PHOTO, photoId)
          .then(() => {
            this.getVehicleByVin();
            this.photoDeleteSuccessMessage();
          })
          .catch(() => {
            this.photoDeleteErrorMessage();
          });
      }
    },
    errorLoadImg(i = null, previewImg = false) {
      this.imgsNotFound.push(i);
      if (previewImg)
        this.imgError = require("@/assets/img/image_not_found.png");
    },
    newImageToPreview(photo) {
      this.imageToPreview = photo;
    },
  },
  watch: {
    vehicleHasPartsProps(newData) {
      if (newData[0]) this.newImageToPreview(newData[0].photo);
    },
    vehicleExternalImagesProps(newData) {
      if (!this.vehicleHasPartsProps.length && newData[0])
        this.newImageToPreview(newData[0].photo);
    },
  },
  mounted() {
    if (this.vehicleHasPartsProps?.length) {
      this.imageToPreview = this.vehicleHasPartsProps[0].photo;
    } else if (this.vehicleExternalImagesProps?.length) {
      this.imageToPreview = this.vehicleExternalImagesProps[0].photo;
    }
  },
};
</script>

<style>
.center-block {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
@media (max-width: 300px) {
  .vehicle-part-content {
    padding-bottom: 45%;
  }
  .vehicle-part-delete {
    padding-left: 175px;
    margin-top: 25%;
  }
  .vehicle-part-img,
  .vehicle-part-name {
    position: absolute;
  }
  .vehicle-part-img {
    width: 170px;
  }
  .vehicle-part-name {
    margin-top: 66%;
  }
}
</style>
