<template>
  <div>
    <div id="filters-menu">
      <div v-if="isMobile" class="sticky-top floatingSideMenu">
        <b-button variant="primary mt-2 sticky-top" @click="toggleShowMenu()">
          <b-icon icon="funnel" class="cursor-pointer sticky-top" scale="1.8" />
        </b-button>
      </div>
      <div v-if="isMobile && showMenu">
        <div v-if="filtersStatus === 'fetching'" class="body-color shadow m-2">
          <b-row>
            <b-col v-for="(data, index) in [1]" :key="index">
              <b-card no-body img-top>
                <b-card-body>
                  <b-skeleton-table
                    :rows="5"
                    :columns="1"
                    :table-props="{ bordered: true, striped: true }"
                  />
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </div>

        <div v-else-if="filtersStatus === 'success'">
          <div v-if="offersView" class="text-center">
            <b>Filter offers</b>
          </div>
          <div class="body-color shadow">
            <!-- Location -->
            <!--         <b-dropdown
          :text="this.location ? this.location : 'Any Location'"
          v-model="location"
          variant="white"
          block
          class="m-2 mb-4"
          menu-class="w-100"
          style="padding-top: 25px"
        >
          <b-dropdown-item @click="location = null">
            Any Location
          </b-dropdown-item>
          <b-dropdown-item
            v-for="(l, i) of filters.location"
            :key="i"
            @click="location = l"
          >
            {{ l }}
          </b-dropdown-item>
        </b-dropdown> -->

            <!-- Stock / Vin Number -->
            <b-row class="mt-4">
              <b-col class="text-center mt-2">
                <strong>{{ $t("stockVin") }}</strong>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col class="ml-2 mr-1">
                <b-input v-model="search" />
              </b-col>
            </b-row>

            <!-- Makes -->
            <b-dropdown
              :text="this.make ? this.make : $t('anyMake')"
              block
              variant="white"
              class="m-2 mt-3"
              menu-class="min-w "
            >
              <b-dropdown-item @click="searchModel(null)">{{
                $t("anyMake")
              }}</b-dropdown-item>
              <b-dropdown-item
                v-for="(m, i) of filters.make"
                :key="i"
                @click="searchModel(m)"
              >
                {{ m }}
              </b-dropdown-item>
            </b-dropdown>

            <!-- Models -->
            <b-dropdown
              block
              variant="white"
              class="m-2 mt-3 text-wrap"
              menu-class="min-w text-wrap"
            >
              <template #button-content>
                <span class="text-wrap">
                  {{ model ? model : $t("anyModel") }}
                </span>
              </template>
              <b-dropdown-item-button
                bv.dropdown.show
                @click="searchTrims(null)"
              >
                {{ $t("anyModel") }}
              </b-dropdown-item-button>
              <b-dropdown-item
                v-for="(m, i) of vehicleModels"
                :key="i"
                class="text-wrap"
                @click="searchTrims(m)"
              >
                {{ m }}
              </b-dropdown-item>
            </b-dropdown>

            <!-- Sale date -->
            <b-row
              v-if="!offersView && (userIsAdmin || userIsAgent)"
              class="mt-3"
            >
              <b-col cols="12" class="text-center">
                <strong>Sale date</strong>
              </b-col>
              <b-col class="ml-1">
                <b-form-group label-size="sm">
                  <template slot="label"> {{ $t("from") }}: </template>
                  <b-form-input
                    v-model="saleDateFrom"
                    size="sm"
                    type="date"
                    @change="applyFilter()"
                    :max="saleDateTo"
                  />
                </b-form-group>
              </b-col>
              <b-col class="mr-1">
                <b-form-group label-size="sm">
                  <template slot="label"> {{ $t("to") }}: </template>
                  <b-form-input
                    v-model="saleDateTo"
                    size="sm"
                    type="date"
                    @change="applyFilter()"
                    :min="saleDateFrom"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Release Dates -->
            <!--   <div v-if="user">
          <b-row class="mt-4">
            <b-col class="text-center">
              <strong>Release Date</strong>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col class="m-2">
              <b-form-input
                v-model="releaseDate1"
                class="text-center"
                type="date"
              />
            </b-col>
            <b-col class="m-2">
              <b-form-input
                :disabled="!releaseDate1"
                v-model="releaseDate2"
                :min="releaseDate1"
                class="text-center"
                type="date"
              />
            </b-col>
          </b-row>
        </div> -->

            <!-- Years -->
            <b-row :class="[user ? 'mt-2' : 'mt-4']">
              <b-col class="text-center">
                <strong>{{ $t("year") }}</strong>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col class="ml-3 mr-3">
                <Slider v-model="yearRange" :min="minYear" :max="maxYear" />
              </b-col>
            </b-row>

            <!-- Mileage -->
            <b-row class="mt-4">
              <b-col class="text-center">
                <strong>{{ $t("mileage") }}</strong>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col class="ml-3 mr-3">
                <Slider v-model="mileageRange" :max="maxMileage" />
              </b-col>
            </b-row>

            <!-- Price -->
            <b-row class="mt-3">
              <b-col class="text-center">
                <strong>{{ $t("price") }} </strong>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col class="ml-3 mr-3">
                <Slider v-model="priceRange" :max="maxPrice" />
              </b-col>
            </b-row>

            <!-- Status -->
            <b-row v-if="!offersView && userIsAdmin" class="mt-2">
              <b-col cols="12" class="">
                <b-dropdown
                  :text="
                    this.status
                      ? this.status === 'READYTOSELL' ||
                        this.status === 'ReadyToSell'
                        ? 'READY TO SELL'
                        : this.status
                      : 'Any Status'
                  "
                  block
                  variant="white"
                  class="m-2 mt-3"
                  menu-class="w-100"
                >
                  <b-dropdown-item @click="status = null">
                    Any Status
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-for="(s, i) of filters.status"
                    :key="i"
                    @click="status = s"
                  >
                    {{
                      s === "READYTOSELL" || s === "ReadyToSell"
                        ? "Ready To Sell"
                        : s | toUpperCase
                    }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-col>
              <b-col cols="12">
                <b-form-checkbox
                  class="ml-2"
                  v-model="published"
                  :value="1"
                  :unchecked-value="0"
                >
                  {{ $t("availableInMarketplace") }}
                </b-form-checkbox>

                <b-form-checkbox
                  class="ml-2"
                  v-model="noPublished"
                  :value="1"
                  :unchecked-value="0"
                >
                  Need pricing
                </b-form-checkbox>
                <b-form-checkbox
                  class="ml-2"
                  v-model="fullInventory"
                  :value="1"
                  :unchecked-value="0"
                >
                  {{ $t("fullInventory") }}
                </b-form-checkbox>
              </b-col>
            </b-row>

            <!-- Client/Buyer -->
            <div v-if="offersView">
              <div v-if="userIsAdmin || userIsAgent">
                <b-row class="mt-4">
                  <b-col class="text-center">
                    <strong>Client</strong>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col class="ml-2 mr-1">
                    <b-form-input v-model="client" />
                  </b-col>
                </b-row>
              </div>

              <!-- Agent --->
              <b-dropdown
                v-if="userIsAdmin"
                :text="this.agent ? this.agent : 'Any Agent'"
                block
                variant="white"
                class="m-2 mb-4"
                menu-class="w-100"
                style="padding-top: 25px"
              >
                <b-dropdown-item
                  @click="
                    agent = null;
                    agentId = null;
                  "
                >
                  Any Agent
                </b-dropdown-item>
                <b-dropdown-item
                  v-for="(a, i) of filters.agents"
                  :key="i"
                  @click="
                    agent = a.firstName + ' ' + a.lastName;
                    agentId = a.id;
                  "
                >
                  {{ a.firstName }} {{ a.lastName }}
                </b-dropdown-item>
              </b-dropdown>
            </div>

            <!-- Actions -->
            <b-row style="padding-bottom: 25px" class="ml-1">
              <b-col>
                <b-button
                  @click="resetFilters(true)"
                  variant="primary"
                  class="mt-4 shadow"
                  size="lg"
                  block
                >
                  {{ $t("showAll") }}
                </b-button>
              </b-col>
              <b-col class="mr-2">
                <b-button
                  class="mt-4 shadow"
                  variant="success"
                  size="lg"
                  @click="searchVehicles"
                  block
                >
                  {{ $t("search") }}
                </b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <div v-if="!isMobile">
        <div v-if="filtersStatus === 'fetching'" class="body-color shadow m-2">
          <b-row>
            <b-col v-for="(data, index) in [1]" :key="index">
              <b-card no-body img-top>
                <b-card-body>
                  <b-skeleton-table
                    :rows="5"
                    :columns="1"
                    :table-props="{ bordered: true, striped: true }"
                  />
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </div>

        <div v-else-if="filtersStatus === 'success'">
          <div v-if="offersView" class="text-center">
            <b>Filter offers</b>
          </div>
          <div class="body-color shadow">
            <!-- Location -->
            <!--         <b-dropdown
          :text="this.location ? this.location : 'Any Location'"
          v-model="location"
          variant="white"
          block
          class="m-2 mb-4"
          menu-class="w-100"
          style="padding-top: 25px"
        >
          <b-dropdown-item @click="location = null">
            Any Location
          </b-dropdown-item>
          <b-dropdown-item
            v-for="(l, i) of filters.location"
            :key="i"
            @click="location = l"
          >
            {{ l }}
          </b-dropdown-item>
        </b-dropdown> -->

            <!-- Stock / Vin Number -->
            <b-row class="mt-4">
              <b-col class="text-center mt-2">
                <strong>{{ $t("stockVin") }}</strong>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col class="ml-2 mr-1">
                <b-input v-model="search" />
              </b-col>
            </b-row>

            <!-- Makes -->
            <b-dropdown
              :text="this.make ? this.make : $t('anyMake')"
              block
              variant="white"
              class="m-2 mt-3"
              menu-class="min-w "
            >
              <b-dropdown-item @click="searchModel(null)">{{
                $t("anyMake")
              }}</b-dropdown-item>
              <b-dropdown-item
                v-for="(m, i) of filters.make"
                :key="i"
                @click="searchModel(m)"
              >
                {{ m }}
              </b-dropdown-item>
            </b-dropdown>

            <!-- Models -->
            <b-dropdown
              block
              variant="white"
              class="m-2 mt-3 text-wrap"
              menu-class="min-w text-wrap"
            >
              <template #button-content>
                <span class="text-wrap">
                  {{ model ? model : $t("anyModel") }}
                </span>
              </template>
              <b-dropdown-item-button
                bv.dropdown.show
                @click="searchTrims(null)"
              >
                {{ $t("anyModel") }}
              </b-dropdown-item-button>
              <b-dropdown-item
                v-for="(m, i) of vehicleModels"
                :key="i"
                class="text-wrap"
                @click="searchTrims(m)"
              >
                {{ m }}
              </b-dropdown-item>
            </b-dropdown>

            <!-- Sale date -->
            <b-row v-if="!offersView && userIsAdmin" class="mt-3">
              <b-col cols="12" class="text-center">
                <strong>{{ $t("saleDate") }}</strong>
              </b-col>
              <b-col class="ml-1">
                <b-form-group label-size="sm">
                  <template slot="label"> {{ $t("from") }}: </template>
                  <b-form-input
                    v-model="saleDateFrom"
                    size="sm"
                    type="date"
                    @change="applyFilter()"
                    :max="saleDateTo"
                  />
                </b-form-group>
              </b-col>
              <b-col class="mr-1">
                <b-form-group label-size="sm">
                  <template slot="label"> {{ $t("to") }}: </template>
                  <b-form-input
                    v-model="saleDateTo"
                    size="sm"
                    type="date"
                    @change="applyFilter()"
                    :min="saleDateFrom"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Release Dates -->
            <!--   <div v-if="user">
          <b-row class="mt-4">
            <b-col class="text-center">
              <strong>Release Date</strong>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col class="m-2">
              <b-form-input
                v-model="releaseDate1"
                class="text-center"
                type="date"
              />
            </b-col>
            <b-col class="m-2">
              <b-form-input
                :disabled="!releaseDate1"
                v-model="releaseDate2"
                :min="releaseDate1"
                class="text-center"
                type="date"
              />
            </b-col>
          </b-row>
        </div> -->

            <!-- Years -->
            <b-row :class="[user ? 'mt-2' : 'mt-4']">
              <b-col class="text-center">
                <strong> {{ $t("year") }}</strong>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col class="ml-3 mr-3">
                <Slider v-model="yearRange" :min="minYear" :max="maxYear" />
              </b-col>
            </b-row>

            <!-- Mileage -->
            <b-row class="mt-4">
              <b-col class="text-center">
                <strong>{{ $t("mileage") }}</strong>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col class="ml-3 mr-3">
                <Slider v-model="mileageRange" :max="maxMileage" />
              </b-col>
            </b-row>

            <!-- Price -->
            <b-row class="mt-3">
              <b-col class="text-center">
                <strong> {{ $t("price") }} </strong>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col class="ml-3 mr-3">
                <Slider v-model="priceRange" :max="maxPrice" />
              </b-col>
            </b-row>

            <!-- Status -->
            <b-row v-if="!offersView && userIsAdmin" class="mt-2">
              <b-col cols="12" class="">
                <b-dropdown
                  :text="
                    this.status
                      ? this.status === 'READYTOSELL' ||
                        this.status === 'ReadyToSell'
                        ? 'READY TO SELL'
                        : this.status
                      : 'Any Status'
                  "
                  block
                  variant="white"
                  class="m-2 mt-3"
                  menu-class="w-100"
                >
                  <b-dropdown-item @click="status = null">
                    Any Status
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-for="(s, i) of filters.status"
                    :key="i"
                    @click="status = s"
                  >
                    {{
                      s === "READYTOSELL" || s === "ReadyToSell"
                        ? "Ready To Sell"
                        : s | toUpperCase
                    }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-col>
              <b-col cols="12">
                <b-form-checkbox
                  class="ml-2"
                  v-model="published"
                  :value="1"
                  :unchecked-value="0"
                >
                  {{ $t("availableInMarketplace") }}
                </b-form-checkbox>

                <b-form-checkbox
                  class="ml-2"
                  v-model="noPublished"
                  :value="1"
                  :unchecked-value="0"
                >
                  {{ $t("needPricing") }}
                </b-form-checkbox>
                <b-form-checkbox
                  class="ml-2"
                  v-model="fullInventory"
                  :value="1"
                  :unchecked-value="0"
                >
                  {{ $t("fullInventory") }}
                </b-form-checkbox>
              </b-col>
            </b-row>

            <!-- Client/Buyer -->
            <div v-if="offersView">
              <div v-if="userIsAdmin || userIsAgent">
                <b-row class="mt-4">
                  <b-col class="text-center">
                    <strong>{{ $t("client") }}</strong>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col class="ml-2 mr-1">
                    <b-form-input v-model="client" />
                  </b-col>
                </b-row>
              </div>

              <!-- Agent --->
              <b-dropdown
                v-if="userIsAdmin"
                :text="this.agent ? this.agent : 'Any Agent'"
                block
                variant="white"
                class="m-2 mb-4"
                menu-class="w-100"
                style="padding-top: 25px"
              >
                <b-dropdown-item
                  @click="
                    agent = null;
                    agentId = null;
                  "
                >
                  Any Agent
                </b-dropdown-item>
                <b-dropdown-item
                  v-for="(a, i) of filters.agents"
                  :key="i"
                  @click="
                    agent = a.firstName + ' ' + a.lastName;
                    agentId = a.id;
                  "
                >
                  {{ a.firstName }} {{ a.lastName }}
                </b-dropdown-item>
              </b-dropdown>
            </div>

            <!-- Actions -->
            <b-row style="padding-bottom: 25px" class="ml-1">
              <b-col>
                <b-button
                  @click="resetFilters(true)"
                  variant="primary"
                  class="mt-4 shadow"
                  size="lg"
                  block
                >
                  {{ $t("showAll") }}
                </b-button>
              </b-col>
              <b-col class="mr-2">
                <b-button
                  class="mt-4 shadow"
                  variant="success"
                  size="lg"
                  @click="searchVehicles"
                  block
                >
                  {{ $t("search") }}
                </b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import Slider from "@vueform/slider/dist/slider.vue2.js";
import { listVehiclesMixin } from "./mixins";
import { generalMixin } from "@/modules/mixin";
import {
  GET_VEHICLE_FILTERS,
  SET_VEHICLES_FILTERS,
  GET_VEHICLES,
  GET_VEHICLE_MODELS,
  GET_VEHICLE_TRIMS,
  CLEAR_VEHICLE_MODELS,
  CLEAR_VEHICLE_TRIMS,
} from "./actions";

export default {
  props: {
    unAuthUser: {
      type: Boolean,
      default: false,
    },
    offersView: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Slider,
  },
  mixins: [listVehiclesMixin, generalMixin],
  computed: {
    filtersStatus() {
      return store.state.adminStore.vehicles.listStore.vehicleFilters.status;
    },
    searchFilters() {
      return store.state.adminStore.vehicles.listStore.vehicles.filters;
    },
    filters() {
      return store.state.adminStore.vehicles.listStore.vehicleFilters.data;
    },
    vehicleModels() {
      return store.state.adminStore.vehicles.listStore.vehicleFilters.models
        .data;
    },
    vehicleTrims() {
      return store.state.adminStore.vehicles.listStore.vehicleFilters.trims
        .data;
    },
    minYear() {
      return parseInt(this.filters.year[0]);
    },
    maxYear() {
      return parseInt(this.filters.year[this.filters.year.length - 1]) ===
        this.minYear
        ? this.minYear + 1
        : parseInt(this.filters.year[this.filters.year.length - 1]);
    },
    isMobile() {
      return this.$vssWidth <= 576 ? true : false;
    },
  },
  data() {
    return {
      showMenu: false,
    };
  },
  methods: {
    getFilters() {
      this.$store
        .dispatch(GET_VEHICLE_FILTERS)
        .then(() => {
          const minY = this.searchFilters.year1
            ? this.searchFilters.year1
            : this.minYear;

          const maxY = this.searchFilters.year2
            ? this.searchFilters.year2
            : this.maxYear;

          this.yearRange = [minY, maxY];
          console.log(this.yearRange);
          if (this.searchVehicles.make)
            this.$store.commit(CLEAR_VEHICLE_MODELS);
          this.$store.commit(CLEAR_VEHICLE_TRIMS);
        })
        .catch(() => {});
    },
    payload() {
      const payload = {
        location: this.location,
        search: this.search,
        make: this.make,
        model: this.model,
        trim: this.trim,
        releaseDate1: this.releaseDate1,
        releaseDate2: this.releaseDate2,
        year1: this.yearRange[0],
        year2: this.yearRange[1],
        mile1: this.mileageRange[0],
        mile2: this.mileageRange[1],
        transmissionType: this.transmissionType,
        price1: this.priceRange[0],
        price2: this.priceRange[1],
        new: null,
        status: this.status,
        client: this.client,
        agent: this.agentId,
        page: 1,
        saleDateFrom: this.saleDateFrom,
        saleDateTo: this.saleDateTo,
        published: this.published,
        noPublished: this.noPublished,
        fullInventory: this.fullInventory,
      };
      return payload;
    },
    getVehicles() {
      this.$store
        .dispatch(GET_VEHICLES, {})
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("Could not get the vehicle list", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    searchModel(make) {
      if (!make) {
        this.$store.commit(CLEAR_VEHICLE_MODELS);
        this.$store.commit(CLEAR_VEHICLE_TRIMS);
        this.make = make;
        this.model = null;
      } else {
        this.$store.dispatch(GET_VEHICLE_MODELS, { make }).then(() => {
          this.make = make;
        });
      }
    },
    searchTrims(model) {
      if (!model) {
        this.$store.commit(CLEAR_VEHICLE_TRIMS);
        this.model = model;
        this.trim = null;
      } else {
        this.$store
          .dispatch(GET_VEHICLE_TRIMS, { make: this.make, model })
          .then(() => {
            this.model = model;
            this.trim = null;
          });
      }
    },
    searchVehicles() {
      this.$store.commit(SET_VEHICLES_FILTERS, { fields: this.payload() });
      this.searchVehiclesByTabMenu();
    },
    getSearchFilters() {
      const minMile = this.searchFilters.mile1 ? this.searchFilters.mile1 : 0;

      const maxMile = this.searchFilters.mile2
        ? this.searchFilters.mile2
        : 200000;

      const minPrice = this.searchFilters.price1
        ? this.searchFilters.price1
        : 0;

      const maxPrice = this.searchFilters.price2
        ? this.searchFilters.price2
        : 200000;

      this.location = this.searchFilters.location;
      this.search = this.searchFilters.search;
      this.make = this.searchFilters.make;
      this.model = this.searchFilters.model;
      this.trim = this.searchFilters.trim;
      this.releaseDate1 = this.searchFilters.releaseDate1;
      this.releaseDate2 = this.searchFilters.releaseDate2;
      this.year1 = this.searchFilters.year1;
      this.year2 = this.searchFilters.year2;
      this.mile1 = this.searchFilters.mile1;
      this.mile1 = this.searchFilters.mile1;
      this.transmissionType = this.searchFilters.transmissionType;
      this.price1 = this.searchFilters.price1;
      this.price2 = this.searchFilters.price2;
      this.mileageRange = [minMile, maxMile];
      this.maxMileage = maxMile;
      this.priceRange = [minPrice, maxPrice];
      this.maxPrice = maxPrice;
      this.status = this.searchFilters.status;
      this.saleDateFrom = this.searchFilters.saleDateFrom;
      this.saleDateTo = this.searchFilters.saleDateTo;
      /*       this.published = this.searchFilters.published;
      this.noPublished = this.searchFilters.noPublished; */
    },
    toggleShowMenu() {
      this.showMenu = !this.showMenu;
      this.scrollToMenu();
    },
    scrollToMenu() {
      /* wait 1 second */

      const element = document.getElementById("filters-menu");
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    },
  },
  mounted() {
    if (
      this.userIsAdmin ||
      this.userIsAgent ||
      this.unAuthUser ||
      this.userIsSuperAdmin
    ) {
      this.getFilters();
    }

    this.getSearchFilters();
  },
};
</script>
<style src="@vueform/slider/themes/default.css"></style>
<style lang="scss">
$blue: #0277bc;

.slider-connect {
  background: $blue;
}

.slider-tooltip {
  border: 1px solid $blue;
  background: $blue;
}

.body-color {
  background-color: #f2f2f2;
}

.dropdown-border-color {
  border-color: black;
}

.min-w {
  min-width: 100% !important;
}
</style>
