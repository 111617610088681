export default [
  {
    authority: "ROLE_SUPER_ADMIN",
    mainRoute: "admin.vehicles.list",
  },
  {
    authority: "ROLE_ADMIN",
    mainRoute: "admin.vehicles.list",
  },
  {
    authority: "ROLE_BUYER",
    mainRoute: "buyer.car-list",
  },
  {
    authority: "ROLE_CLIENT",
    mainRoute: "client.car-list",
  },
  {
    authority: "ROLE_AGENT",
    mainRoute: "agent.vehicles.list",
  },
  {
    authority: "ROLE_REVIEWER",
    mainRoute: "admin.assign-company",
  },
  {
    authority: "ROLE_WHOLESALE",
    mainRoute: "buyer.car-list"
  },
  {
    authority: "ROLE_RETAIL",
    mainRoute: "buyer.car-list"
  }
];
