import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    users: {
      status: null,
      data: null,
      error: null,
    },
    assignCompany: {
      status: null,
      error: null,
    },
    companiesToAssign: {
      status: null,
      data: [],
      error: null,
    },
    client: {
      status: null,
      data: null,
      error: null,
    },
    companiesAndPreCompany: {
      status: null,
      data: [],
      error: null,
    },
    sendEmail: {
      status: null,
      error: null,
    },
    userRole: {
      status: null,
      data: null,
      error: null,
    }

  };
}

const actions = {
  [constants.GET_USER_TO_ASSIGN_COMPANY]: async ({ commit }, params) => {
    try {
      commit(constants.GET_USER_TO_ASSIGN_COMPANY);

      const response = await axios.get(`company/listUserWithoutCompany`, {
        params: { ...params },
      });
      commit(constants.GET_USER_TO_ASSIGN_COMPANY_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_USER_TO_ASSIGN_COMPANY_ERROR, error);
      throw error.response;
    }
  },
  [constants.PUT_ASSIGN_COMPANY]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_ASSIGN_COMPANY);
      const response = await axios.put(`company/assignCompany`, payload);
      commit(constants.PUT_ASSIGN_COMPANY_SUCCESS);
      return response;
    } catch (error) {
      commit(constants.PUT_ASSIGN_COMPANY_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_COMPANIES_LIST]: async ({ commit }, params) => {
    try {
      commit(constants.GET_COMPANIES_LIST);
      const response = await axios.get(`company/listCompany`,
        { params: { ...params } });
      commit(constants.GET_COMPANIES_LIST_SUCCESS, response);
      return response;
    } catch (error) {
      console.log(error);
      commit(constants.GET_COMPANIES_LIST_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_CLIENT]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_CLIENT);
      const response = await axios.post(`user/createUserCanam`, payload);
      commit(constants.POST_CLIENT_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_CLIENT_ERROR, error);
      throw error.response;
    }
  },
  [constants.PUT_REJECT_COMPANY]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_REJECT_COMPANY);
      const response = await axios.put(`company/rejectCompany`, payload);
      commit(constants.PUT_REJECT_COMPANY_SUCCESS);
      return response;
    } catch (error) {
      commit(constants.PUT_REJECT_COMPANY_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_COMPANY_LIST_AND_PRE]: async ({ commit }, params) => {
    try {
      commit(constants.GET_COMPANY_LIST_AND_PRE);
      const response = await axios.get(`company/listCompanyAndPreCompany`, {
        params: { ...params },
      });
      commit(constants.GET_COMPANY_LIST_AND_PRE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_COMPANY_LIST_AND_PRE_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_SEND_EMAIL_COMPANY_FORM]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_SEND_EMAIL_COMPANY_FORM);
      const response = await axios.post(`user/send-company-email`, payload);
      commit(constants.POST_SEND_EMAIL_COMPANY_FORM_SUCCESS);
      return response;
    } catch (error) {
      commit(constants.POST_SEND_EMAIL_COMPANY_FORM_ERROR, error);
      throw error.response;
    }
  },
  [constants.PUT_CHANGE_BUYER_ROLE]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_CHANGE_BUYER_ROLE);
      const response = await axios.put(`user`, payload);
      commit(constants.PUT_CHANGE_BUYER_ROLE_SUCCESS);
      return response;
    } catch (error) {
      commit(constants.PUT_CHANGE_BUYER_ROLE_ERROR, error);
      throw error.response;
    }
  }


};

const mutations = {
  [constants.GET_USER_TO_ASSIGN_COMPANY]: (state) => {
    state.users.status = "fetching";
    state.users.error = null;
  },
  [constants.GET_USER_TO_ASSIGN_COMPANY_SUCCESS]: (state, data) => {
    state.users.status = "success";
    state.users.error = null;
    state.users.data = data;
  },
  [constants.GET_USER_TO_ASSIGN_COMPANY_ERROR]: (state, error) => {
    state.users.status = "error";
    state.users.error = error;
  },
  [constants.PUT_ASSIGN_COMPANY]: (state) => {
    state.assignCompany.status = "fetching";
    state.assignCompany.error = null;
  },
  [constants.PUT_ASSIGN_COMPANY_SUCCESS]: (state) => {
    state.assignCompany.status = "success";
    state.assignCompany.error = null;
  },
  [constants.PUT_ASSIGN_COMPANY_ERROR]: (state, error) => {
    state.assignCompany.status = "error";
    state.assignCompany.error = error;
  },

  [constants.GET_COMPANIES_LIST]: (state) => {
    state.companiesToAssign.status = "fetching";
    state.companiesToAssign.error = null;
  },
  [constants.GET_COMPANIES_LIST_SUCCESS]: (state, data) => {
    state.companiesToAssign.status = "success";
    state.companiesToAssign.error = null;
    state.companiesToAssign.data = data;
  },
  [constants.GET_COMPANIES_LIST_ERROR]: (state, error) => {
    state.companiesToAssign.status = "error";
    state.companiesToAssign.error = error;
  },
  [constants.POST_CLIENT]: (state) => {
    state.client.status = "fetching";
    state.client.error = null;
  },
  [constants.POST_CLIENT_SUCCESS]: (state, data) => {
    state.client.status = "success";
    state.client.error = null;
    state.client.data = data;
  },
  [constants.POST_CLIENT_ERROR]: (state, error) => {
    state.client.status = "error";
    state.client.error = error;
  },
  [constants.PUT_REJECT_COMPANY]: (state) => {
    state.assignCompany.status = "fetching";
    state.assignCompany.error = null;
  },
  [constants.PUT_REJECT_COMPANY_SUCCESS]: (state) => {
    state.assignCompany.status = "success";
    state.assignCompany.error = null;
  },
  [constants.PUT_REJECT_COMPANY_ERROR]: (state, error) => {
    state.assignCompany.status = "error";
    state.assignCompany.error = error;
  },
  /* add new item to company list */
  [constants.ADD_NEW_COMPANY]: (state, data) => {
    if (state.companiesToAssign.data) {
      state.companiesToAssign.data.push(data);
    }
  },
  [constants.GET_COMPANY_LIST_AND_PRE]: (state) => {
    state.companiesAndPreCompany.status = "fetching";
    state.companiesAndPreCompany.error = null;
  },
  [constants.GET_COMPANY_LIST_AND_PRE_SUCCESS]: (state, data) => {
    state.companiesAndPreCompany.status = "success";
    state.companiesAndPreCompany.error = null;
    state.companiesAndPreCompany.data = data;
  },
  [constants.GET_COMPANY_LIST_AND_PRE_ERROR]: (state, error) => {
    state.companiesAndPreCompany.status = "error";
    state.companiesAndPreCompany.error = error;
  },
  [constants.POST_SEND_EMAIL_COMPANY_FORM]: (state) => {
    state.sendEmail.status = "fetching";
    state.sendEmail.error = null;
  },
  [constants.POST_SEND_EMAIL_COMPANY_FORM_SUCCESS]: (state) => {
    state.sendEmail.status = "success";
    state.sendEmail.error = null;
  },
  [constants.POST_SEND_EMAIL_COMPANY_FORM_ERROR]: (state, error) => {
    state.sendEmail.status = "error";
    state.sendEmail.error = error;
  },
  [constants.PUT_CHANGE_BUYER_ROLE]: (state) => {
    state.userRole.status = "fetching";
    state.userRole.error = null;
  },
  [constants.PUT_CHANGE_BUYER_ROLE_SUCCESS]: (state) => {
    state.userRole.status = "success";
    state.userRole.error = null;
  },
  [constants.PUT_CHANGE_BUYER_ROLE_ERROR]: (state, error) => {
    state.userRole.status = "error";
    state.userRole.error = error;
  }

};

export default {
  state: getDefaultState(),
  actions,
  mutations,
};
