<template>
  <div class="">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "Auth",
};
</script>

<style></style>
