<template>
  <div v-if="status === 'fetching'" class="text-center">
    <b-spinner variant="primary" />
  </div>
  <div v-else>
    <b-card
      header-bg-variant="primary"
      header-text-variant="white"
      class="shadow mb-3 mt-3"
    >
      <template #header>
        <span class="float-right text-white">
          <strong>(VIN) {{ vehicle.vinData.vinNumber }} </strong>
        </span>
      </template>
      <!-- Row 1 -->
      <div class="grid">
        <div class="nested-grid">
          <div>
            <small>
              <strong>{{ $t("year") }}</strong
              ><span class="text-danger"> *</span>
            </small>
          </div>
          <div>
            <b-form-group>
              <b-form-input
                readonly
                class="text-center"
                type="text"
                @change="setValue('vinData', 'year', $event)"
                :value="vehicle.vinData.year"
                v-model.trim="$v.vehicle.vinData.year.$model"
                :state="validateState('vinData', 'year')"
              />
              <b-form-invalid-feedback>
                <div v-if="!$v.vehicle.vinData.year.required">
                  This field is required
                </div>
                <div
                  v-if="
                    !$v.vehicle.vinData.year.minValue ||
                    !$v.vehicle.vinData.year.maxValue
                  "
                >
                  Invalid value
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>
        <!-- Make -->
        <div class="nested-grid">
          <div>
            <small>
              <strong>{{ $t("make") }}</strong>
              <span class="text-danger"> *</span>
            </small>
          </div>
          <div>
            <b-form-group>
              <b-form-input
                readonly
                class="text-center"
                type="text"
                @change="setValue('vinData', 'make', $event)"
                :value="vehicle.vinData.make"
                v-model.trim="$v.vehicle.vinData.make.$model"
                :state="validateState('vinData', 'make')"
              />
              <b-form-invalid-feedback>
                <div v-if="!$v.vehicle.vinData.make.required">
                  This field is required
                </div>
                <div
                  v-if="
                    !$v.vehicle.vinData.make.maxLength ||
                    !$v.vehicle.vinData.make.minLength
                  "
                >
                  Invalid value
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>
        <!-- Model -->
        <div class="nested-grid">
          <div>
            <small>
              <strong>{{ $t("model") }}</strong>
              <span class="text-danger"> *</span>
            </small>
          </div>
          <div>
            <b-form-group>
              <b-form-input
                readonly
                class="text-center"
                type="text"
                @change="setValue('vinData', 'model', $event)"
                :value="vehicle.vinData.model"
                v-model.trim="$v.vehicle.vinData.model.$model"
                :state="validateState('vinData', 'model')"
              />
              <b-form-invalid-feedback>
                <div v-if="!$v.vehicle.vinData.model.required">
                  This field is required
                </div>
                <div
                  v-if="
                    !$v.vehicle.vinData.model.maxLength ||
                    !$v.vehicle.vinData.model.minLength
                  "
                >
                  Invalid value
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>
        <!-- Trim -->
        <div class="nested-grid">
          <div>
            <small
              ><strong>{{ $t("trim") }}</strong></small
            >
          </div>
          <div>
            <b-form-group>
              <b-form-input
                readonly
                class="text-center"
                type="text"
                :value="vehicle.vinData.trim"
                v-model.trim="vehicle.vinData.trim"
              />
            </b-form-group>
          </div>
        </div>
      </div>

      <!-- Row 2 -->
      <div class="grid">
        <!-- Options -->
        <!--    <div class="nested-grid">
          <div>
            <small>
              <strong>Options</strong>
            </small>
          </div>
          <div>
            <b-form-group>
              <b-form-input
                readonly
                class="text-center"
                type="text"
                :value="vehicle.vinData.options"
                v-model.trim="vehicle.vinData.options"
              />
            </b-form-group>
          </div>
        </div> -->
        <!-- Style -->
        <div class="nested-grid">
          <div>
            <small><strong>Style</strong></small>
          </div>
          <div>
            <b-form-group>
              <b-form-input
                readonly
                class="text-center"
                type="text"
                :value="vehicle.vinData.style"
                v-model.trim="vehicle.vinData.style"
              />
            </b-form-group>
          </div>
        </div>
        <!--Doors -->
        <!--    <div class="nested-grid">
          <div>
            <small><strong>Doors</strong></small>
          </div>
          <div>
            <b-form-group>
              <b-form-input
                readonly
                class="text-center"
                type="text"
                :value="vehicle.vinData.doors"
                v-model.trim="vehicle.vinData.doors"
              />
            </b-form-group>
          </div>
        </div> -->
        <!-- Vehicle type -->
        <div class="nested-grid">
          <div>
            <small
              ><strong>{{ this.$t("vehicleType") }}</strong></small
            >
          </div>
          <div>
            <b-form-group>
              <b-form-input
                disabled
                class="text-center"
                type="text"
                :value="vehicle.vinData.vehicleType"
                v-model.trim="vehicle.vinData.vehicleType"
              />
            </b-form-group>
          </div>
        </div>
      </div>

      <!-- Row 3 -->
      <div class="grid">
        <!-- Drive type -->
        <!--     <div class="nested-grid">
          <div>
            <small><strong>Drive Type</strong></small>
          </div>
          <div>
            <b-form-group>
              <b-form-input
                disabled
                class="text-center"
                type="text"
                :value="vehicle.vinData.driveType"
                v-model.trim="vehicle.vinData.driveType"
              />
            </b-form-group>
          </div>
        </div> -->

        <!-- Engine -->
        <!--  <div class="nested-grid">
          <div>
            <small><strong>Engine</strong></small>
          </div>
          <div>
            <b-form-group>
              <b-form-input
                disabled
                class="text-center"
                type="text"
                :value="vehicle.vinData.engine"
                v-model.trim="vehicle.vinData.engine"
              />
            </b-form-group>
          </div>
        </div> -->

        <!-- Transmission -->
        <!--   <div>
            <small><strong>Transmission</strong></small>
          </div>
          <div>
            <b-input-group v-if="!disabled" class="d-flex justify-content-end">
              <b-form-radio-group
                disabled
                style="max-height: 2.3rem"
                class="transmission"
                :value="vehicle.vinData.transmissionType"
                v-model.trim="vehicle.vinData.transmissionType"
                :options="typeOptions"
                name="transmission"
                button-variant="outline-primary"
                buttons
              />
            </b-input-group>
            <b-form-group v-else>
              <b-form-input
                class="text-center"
                :disabled="disabled"
                type="text"
                :value="car.transmissionType"
              />
            </b-form-group>
          </div> -->

        <!-- Color -->
        <div class="nested-grid">
          <div>
            <small>
              <strong>{{ $t("color") }}</strong>
              <span class="text-danger"> *</span>
            </small>
          </div>
          <div>
            <b-form-group>
              <b-form-input
                readonly
                class="text-center"
                :disabled="disabled"
                @change="setValue('vehicleInformation', 'color', $event)"
                :value="vehicle.vehicleInformation.color"
                v-model.trim="$v.vehicle.vehicleInformation.color.$model"
                :state="validateState('vehicleInformation', 'color')"
                type="text"
              />
              <b-form-invalid-feedback>
                <div v-if="!$v.vehicle.vehicleInformation.color.required">
                  This field is required
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>
      </div>

      <!-- Row 4 -->
      <div class="grid">
        <!-- Miles -->
        <div class="nested-grid">
          <div>
            <small>
              <strong>{{ $t("mileage") }}</strong>
              <span class="text-danger"> *</span>
            </small>
          </div>
          <div>
            <b-form-group>
              <b-form-input
                readonly
                class="text-center"
                :disabled="disabled"
                @change="setValue('vehicleInformation', 'miles', $event)"
                :value="vehicle.vehicleInformation.miles"
                v-model.trim="$v.vehicle.vehicleInformation.miles.$model"
                :state="validateState('vehicleInformation', 'miles')"
                type="number"
              />
              <b-form-invalid-feedback>
                <div v-if="!$v.vehicle.vehicleInformation.miles.required">
                  This field is required
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>

        <!-- Cost -->
        <div class="nested-grid">
          <div>
            <small>
              <strong>Cost</strong>
              <span class="text-danger"> *</span>
            </small>
          </div>
          <div>
            <b-form-group>
              <Money
                disabled
                style="background-color: #0277bc"
                class="form-control text-center text-white"
                v-model.trim="$v.vehicle.vehicleCostDetail.costUsd.$model"
                v-bind="money"
                :state="validateState('vehicleCostDetail', 'costUsd')"
                @change="setValue('vehicleCostDetail', 'costUsd', $event)"
              />
              <b-form-invalid-feedback>
                <div v-if="!$v.vehicle.vehicleCostDetail.costUsd.required">
                  This field is required
                </div>
                <div
                  v-if="
                    !$v.vehicle.vehicleCostDetail.costUsd.maxLength ||
                    !$v.vehicle.vehicleCostDetail.costUsd.minLength
                  "
                >
                  Invalid value
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>

        <!-- Price -->
        <!--         <div class="nested-grid">
          <div>
            <small>
              <strong>Price <span class="text-danger"> *</span> </strong>
            </small>
          </div>
          <div>
            <b-form-group>
              <Money
                disabled
                style="background-color: #35a4dd"
                class="form-control text-center text-white"
                v-model.trim="$v.vehicle.vehicleCostDetail.price.$model"
                v-bind="money"
                :state="validateState('vehicleCostDetail', 'price')"
                @change="setValue('vehicleCostDetail', 'price', $event)"
              />
              <b-form-invalid-feedback>
                <div v-if="!$v.vehicle.vehicleCostDetail.price.required">
                  This field is required
                </div>
                <div
                  v-if="
                    !$v.vehicle.vehicleCostDetail.price.maxLength ||
                    !$v.vehicle.vehicleCostDetail.price.minLength
                  "
                >
                  Invalid value
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div> -->
      </div>
    </b-card>

    <b-card
      header-bg-variant="primary"
      header-text-variant="white"
      class="shadow mb-3 mt-3"
    >
      <!-- Row 1 -->

      <div class="row">
        <div class="col-auto">
          <div>
            <small>
              <strong>{{ $t("retailPrice") }}</strong>
            </small>
          </div>
          <div>
            <Money
              style="background-color: #0277bc"
              class="form-control text-center text-white"
              v-model="vehicle.vehicleCostDetail.retailPrice"
              v-bind="money"
            />
          </div>
        </div>
        <div class="col-auto">
          <div>
            <small>
              <strong>{{ $t("wholesalePrice") }}</strong>
            </small>
          </div>
          <div>
            <Money
              style="background-color: #0277bc"
              class="form-control text-center text-white"
              v-model="vehicle.vehicleCostDetail.wholesalePrice"
              v-bind="money"
            />
          </div>
        </div>
        <!--  <div class="nested-grid">
          <div>
            <small>
              <strong>Reserve price</strong>
            </small>
          </div>
          <div>
            <Money
              style="background-color: #35a4dd"
              class="form-control text-center text-white"
              v-model="vehicle.vehicleCostDetail.reservedPrice"
              v-bind="money"
            />
          </div>
        </div> -->
        <div class="col-auto">
          <b-button variant="success" @click="saveVehiclePrice()" class="mt-4"
            >{{ $t("save") }}
          </b-button>
        </div>
      </div>
    </b-card>
    <div v-if="car">
      <UploadPhotos
        :vehicleId="car.id"
        :vehicleHasPartsProps="car.vehicleHasParts"
        :vehicleExternalImagesProps="car.vehicleExternalImages"
      />
    </div>
    <!--     <b-container fluid class="mt-4">
      <b-row>
        <b-col sm="2" xl="6" class="d-flex">

        </b-col>
        <b-col sm="2" xl="6" class="d-flex justify-content-end">
          <div class="cancel-next-button">
            <b-button
              variant="outline-secondary"
              class="mr-2"
              size="lg"
              @click="cancelEdit()"
            >
              Cancel
            </b-button>
            <b-button
              size="lg"
              type="submit"
              variant="blue"
              :disabled="$v.vehicle.$invalid"
              @click="nextPage"
            >
              Next
              <b-icon icon="chevron-compact-right" scale="1" />
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-container> -->
  </div>
</template>

<script>
import { Money } from "v-money";
import { registryVehicleMixin } from "./mixins";
import { generalMixin } from "@/modules/mixin";
import {
  required,
  maxValue,
  minValue,
  maxLength,
  minLength,
} from "vuelidate/lib/validators";
import UploadPhotos from "./uploadPhotos/UploadPhotos";
import { PUT_VEHICLE_PRICE } from "./actions";
import { mapState } from "vuex";
export default {
  components: { Money, UploadPhotos },
  props: ["carProp", "disabled", "reservedPriceProp"],
  mixins: [registryVehicleMixin, generalMixin],
  computed: {
    ...mapState({
      vehicleItem: (state) =>
        state.adminStore.vehicles.carRegistry.vehicleItem.data,
      vehicles: (state) => state.adminStore.vehicles.listStore.vehicles.data,
    }),
  },
  data() {
    return {
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "USD$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      typeOptions: [
        { value: "Manual", text: "M" },
        { value: "Automatic", text: "A" },
        { value: "CVT", text: "CVT" },
      ],
      vehicleCost: {
        published: 1,
        noPublished: 0,
        price: 0,
        reservedPrice: null,
        typeCurrency: "USD",
        vehicleId: this.vehicle?.id || null,
      },
    };
  },
  watch: {
    vehicleItem() {
      this.formatVehicleCost();
    },
  },

  validations: {
    vehicle: {
      stockNumber: {
        required,
      },
      crUrl: {
        maxLength: maxLength(255),
        minLength: minLength(5),
      },
      userId: {
        required,
      },
      location: {
        required,
      },
      vehicleCostDetail: {
        costUsd: {
          required,
          maxValue: maxValue(1000000),
          minValue: minValue(1),
        },
        price: {
          required,
          maxValue: maxValue(1000000),
          minValue: minValue(1),
        },
      },
      vehicleInformation: {
        color: {
          required,
        },
        miles: {
          required,
          maxValue: maxValue(1000000),
          minValue: minValue(1),
        },
      },
      vinData: {
        year: {
          required,
          maxValue: maxValue(2050),
          minValue: minValue(1900),
        },
        make: {
          required,
          maxLength: maxLength(50),
          minLength: minLength(2),
        },
        model: {
          required,
          maxLength: maxLength(50),
          minLength: minLength(2),
        },
      },
      releaseDate: {},
    },
  },

  methods: {
    setValue(key, attribute, value) {
      if (key) {
        this.vehicle[key][attribute] = value;
        this.$v.vehicle[key][attribute].$touch();
      } else {
        this.vehicle[attribute] = value;
        this.$v.vehicle[attribute].$touch();
      }
    },
    validateState(key, name) {
      const { $dirty, $error } = key
        ? this.$v.vehicle[key][name]
        : this.$v.vehicle[name];
      return $dirty ? !$error : null;
    },
    confirmDeleteVehicle() {
      return this.$bvModal.msgBoxConfirm(
        "Please confirm that you want to delete the vehicle.",
        {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        }
      );
    },
    async deleteVehicle() {
      const confirm = await this.confirmDeleteVehicle();
      if (!confirm) {
        return;
      } else {
        this.updateStatus("Inactive");
      }
    },
    cancelEdit() {
      this.$router.push({
        name: this.userIsAdmin ? "admin.vehicles.list" : "agent.vehicles.list",
      });
    },
    saveVehiclePrice() {
      const payload = {
        retailPrice: this.vehicle.vehicleCostDetail.retailPrice,
        wholesalePrice: this.vehicle.vehicleCostDetail.wholesalePrice,
        vehicleId: this.vehicle.id,
      };
      this.vehicleCost.vehicleId = this.vehicle.id;
      this.$store.dispatch(PUT_VEHICLE_PRICE, payload).then(() => {
        this.updateVehicleInStore(
          this.vehicle,
          payload.retailPrice,
          payload.wholesalePrice
        );
        this.$store.commit("SET_IS_SHOWING_VIN_INFORMATION_SIDEBAR", {
          status: false,
          data: null,
        });
        this.$bvToast.toast("Vehicle price updated successfully", {
          title: "Success",
          variant: "success",
          solid: true,
        });
      });
    },
    updateVehicleInStore(vehicle, retailPrice, wholesalePrice) {
      const originalVehiclesWithPagination = JSON.parse(
        JSON.stringify(this.vehicles)
      );
      const vehiclesToEdit = JSON.parse(JSON.stringify(this.vehicles.data));

      const vehicleIndex = vehiclesToEdit.findIndex(
        (item) => item.id === vehicle.id
      );

      const vehicleWithPrice = vehiclesToEdit[vehicleIndex];
      vehicleWithPrice.priceRetail = retailPrice;
      vehicleWithPrice.priceWholesale = wholesalePrice;
      vehiclesToEdit[vehicleIndex] = vehicleWithPrice;
      const vehiclesUpdated = vehiclesToEdit;
      originalVehiclesWithPagination.data = vehiclesUpdated;

      this.$store.commit(
        "GET_VEHICLES_SUCCESS",
        originalVehiclesWithPagination
      );
    },
  },
  beforeMount() {
    this.clearVehicleToEdit();
    this.getVehicleByVin();
    /*        this.getUserNickNames();
     this.getLocations(); */
    this.formatVehicleCost();
  },
};
</script>

<style scoped>
.border-left {
  border-left: 5px black;
}
.bg-primary {
  background-color: #35a4dd;
}
.font-small {
  font-size: 12px;
}
.border-b {
  border-bottom: 10px black;
}
.transmission {
  width: 100%;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1em;
}

.nested-grid {
  display: grid;
  grid-template-columns: 30% 70%;
}

@media (max-width: 576px) {
  .grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .cancel-next-button {
    margin-top: -45px;
  }
}

@media (max-width: 300px) {
  .nested-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .trash-button {
    position: absolute;
    margin-top: -15px;
    margin-left: 10px;
  }
  .cancel-next-button {
    margin-top: 40px;
  }
}
</style>
