/* import Home from "./Home";
 */import { ifAuthenticated } from "@/router/navigationGuards";
/* import List from "../admin/vehicles/list/List.vue";
 */import Login from "../auth/login/LoginForm.vue";
const routes = [
  {
    path: "/",
    name: "home",
    component: Login,
    beforeEnter: (to, from, next) => {
      ifAuthenticated(to, from, next, "ROLE_ADMIN");
    },
  },
];
export default routes;
