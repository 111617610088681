<template>
  <div class="wrapper">
    <NavBar />
    <b-navbar class="v-25 text-dark-green bg-primary">
      <b-icon
        v-if="$route.name !== 'home'"
        class="my-2"
        icon="arrow-left-circle"
        variant="white"
        scale="1.5"
        @click="$router.go(-1)"
      />
      <div v-else class=""></div>
    </b-navbar>

    <div class="content">
      <router-view />
    </div>
    <div class="text-center footer bg-primary">
      <p class="text-white pb-3 pt-4 mt-0 mb-0 container">
        Copyright 2021 Proleasing Powered by
        <a href="https://propulsoft.ca/"> Propulsoft.ca</a>
      </p>
    </div>
  </div>
</template>

<script>
import NavBar from "./NavBar";

export default {
  name: "Base",
  components: { NavBar },
};
</script>

<style scope>
.mt-8 {
  margin-top: 8rem !important;
}
@media only screen and (max-width: 600px) {
  .mt-8 {
    margin-top: 2rem !important;
  }
}
.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.content {
  flex-grow: 1;
}
.footer {
  height: auto;
}
</style>
