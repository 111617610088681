<template>
  <b-container fluid class="mt-0">
    <div class="table-responsive"></div>
    <b-table
      responsive
      thead-class="text-center bg-light-grey "
      head-variant="light"
      bordered
      small
      show-empty
      outlined
      :items="vehicles"
      :fields="fields"
      stacked="sm"
    >
      <template v-slot:cell(image)="{ item }">
        <div class="mx-auto">
          <VehiclesListViewImage :vehicle="item" />

          <div class="text-center bg-primary">
            <strong>
              <i class="text-white">
                {{ item.condition }}
              </i>
            </strong>
          </div>
        </div>
      </template>
      <template v-slot:cell(vinNumber)="{ item }">
        <router-link v-if="item.vinNumber" :to="getVehiclePageUrl(item)">
          <div>
            <i>
              {{ item.vinNumber }}
            </i>
          </div>
        </router-link>
      </template>
      <template v-slot:cell(year)="{ item }">
        {{ item.year }}
      </template>
      <template v-slot:cell(make)="{ item }">
        {{ item.make }}
      </template>
      <template v-slot:cell(model)="{ item }">
        {{ item.model }}
      </template>

      <template v-slot:cell(miles)="{ item }">
        {{ item.miles | formatNumber }} Kms
      </template>

      <template v-slot:cell(cr)="{ item }">
        <CRButton
          v-if="item.vehicleHasConditionalReports.length > 0"
          :vehicle="item"
          class="mx-auto"
        />
      </template>
      <template v-slot:cell(actions)="{ item }">
        <div class="mb-2 pt-2 text-center" v-if="item && item.priceRetail">
          <strong>
            {{ item.priceRetail | currency }}
          </strong>
        </div>
        <div
          v-if="vehicleAcceptOffer(item) || item.status === 'Sold'"
          class="m"
        >
          <img
            src="@/assets/img/TOOLATE.png"
            alt="sold-image"
            class="img-fluid"
          />
        </div>
        <div v-else>
          <b-row v-if="viewOffers" class="">
            <b-col class="mb-1">
              <b-button
                variant="primary"
                block
                @click="showVehicleOffers(item)"
                class="shadow"
              >
                {{ $t("viewOffers") }}
              </b-button>
            </b-col>
          </b-row>

          <b-row class="" v-else-if="!isHome && user">
            <b-col>
              <b-button
                v-if="item.status !== 'Inactive' && userIsAdmin"
                variant="primary"
                block
                class=""
                @click="editVehicle(item.vinNumber)"
              >
                <b-icon icon="pencil" />
              </b-button>
            </b-col>
          </b-row>
          <!--             v-if="item.vehicleFlags.wo != 0 && userIsAdmin"
 -->
          <!--      <img
            class="img-responsive mt-1"
            height="25"
            src="@/assets/icon/work-order-icon.png"
            v-b-tooltip.hover
            title="This vehicle has had a work order"
          /> -->

          <b-row
            v-if="
              !isHome &&
              !isActiveOffer &&
              !isExpiredOffer &&
              (userHasOffer || item.lastOffer) &&
              userHasCompany
            "
            class=""
          >
            <b-col>
              <b-button
                variant="primary"
                block
                size="sm"
                @click="changeOfferTab(item.id)"
              >
                <h5 class="">
                  {{ $t("currentOffer") }}:
                  <span v-if="item.lastOffer">
                    {{ item.lastOffer.offerPrice | currency }}
                  </span>
                  <span v-else-if="userHasOffer">
                    {{ currentOffer | currency }}
                  </span>
                </h5>
              </b-button>
            </b-col>
          </b-row>
        </div>
        <MakeAnOffer
          v-if="
            !isHome &&
            !userHasOffer &&
            !item.lastOffer &&
            !isExpiredOffer &&
            !isActiveOffer &&
            !userIsAdmin &&
            !userIsAgent &&
            !userIsRetail
          "
          :vehicleId="item.id"
          :price="item.priceRetail"
          :userPending="!userHasCompany"
          @setUserHasOffer="setUserHasOffer($event)"
          :stacked="true"
          :vehicle="item"
          @refresh="refreshVehicles()"
        />

        <b-button
          class="mt-2"
          variant="primary"
          block
          @click="copyToClipboard(generateUrlToCopy(item))"
        >
          {{ $t("copyLink") }}
        </b-button>
      </template>
    </b-table>
  </b-container>
</template>

<script>
import CRButton from "./VehicleCard/CRButton.vue";
import MakeAnOffer from "./components/MakeAnOffer";
import { SET_VEHICLES_FILTERS } from "./actions";
import VehiclesListViewImage from "./VehiclesListViewImage.vue";

import {
  SHOW_OFFERS_SIDEBAR,
  SHOW_OFFER_SIDEBAR,
  SET_OFFER_SIDEBAR,
  GET_OFFERS_BY_VEHICLE,
} from "@/modules/admin/vehicles/offers/actions";
import { listVehiclesMixin } from "./mixins";
import { generalMixin } from "@/modules/mixin";
/* import CardTitle from "./VehicleCard/CardTitle.vue";
 */ import IMAGES_PARTS from "./VehicleCard/images-parts";

export default {
  components: {
    CRButton,

    MakeAnOffer,
    VehiclesListViewImage,
  },
  name: "VehiclesListView",
  props: {
    vehicles: {
      type: Array,
      required: true,
    },
    viewOffers: {
      type: Boolean,
      default: false,
    },
    isActiveOffer: {
      type: Boolean,
      default: false,
    },
    isExpiredOffer: {
      type: Boolean,
      default: false,
    },
    refresh: {
      type: Function,
      default: () => {},
    },
  },
  mixins: [listVehiclesMixin, generalMixin],
  data() {
    return {
      fields: [
        {
          key: "image",
          label: "",
          class: "text-center ",
        },
        {
          key: "vinNumber",
          label: this.$t("vin"),
          class: "text-center",
        },
        {
          key: "year",
          label: this.$t("year"),
          class: "text-center",
        },
        {
          key: "make",
          label: this.$t("make"),
          class: "text-center",
        },
        {
          key: "model",
          label: this.$t("model"),
          class: "text-center",
        },

        {
          key: "miles",
          label: "Kms",
          class: "text-center",
        },

        {
          key: "description",
          label: this.$t("description"),
          class: "text-center",
        },
        {
          key: "actions",
          label: "Actions",
          class: "text-center",
        },
      ],
      imageProps: {
        height: "100px",
        width: "100px",
        block: true,
        rounded: true,
      },
      userHasOffer: false,
      imagesFromSpace: [],
      BASE_URL: "https://proleasing.nyc3.cdn.digitaloceanspaces.com/Prod",
      isLoadingImagesFromSpace: false,
    };
  },
  computed: {
    isHome() {
      return this.$route.name === "home" ? true : false;
    },
  },
  methods: {
    showVehicleDetails(vehicle) {
      this.$emit("showVehicleDetails", vehicle);
    },

    formatImages(images) {
      console.log(images);
      return images.map((image) => {
        return {
          id: image.id,
          src: image.src,
          largeViewSrc: image.largeViewSrc,
          thumbnail: image.thumbnail || image.photo,
        };
      });
    },
    getImagesOfVehicle(vehicle) {
      if (vehicle.vehicleHasParts.length == 0) {
        console.log("vehicle", this.formatImagesFromSpace(vehicle));
        return this.formatImagesFromSpace(vehicle);
      }
      let images = [];
      if (vehicle.vehicleHasParts) {
        vehicle.vehicleHasParts.forEach((part) => {
          images.push({
            photo: part.photo,
            photoThumbnail: part.photoThumbnail,
          });
        });
      }
      if (vehicle.vehicleExternalImages) {
        vehicle.vehicleExternalImages.forEach((image) => {
          images.push({
            photo: image.photo,
            photoThumbnail: image.photoThumbnail,
          });
        });
      }
      return images;
    },

    userOffer() {
      return {};
    },
    lastOfferUser(vehicle) {
      return vehicle.lastOfferUser;
    },
    lastOfferAdmin(vehicle) {
      return vehicle.lastOfferAdmin;
    },

    vehicleDetailsSideBar() {
      return this.$store.state.adminStore.vehicles.listStore.componentsStore
        .vehicleDetailsSideBar;
    },
    offerSideBar() {
      return this.$store.state.adminStore.vehicles.offersStore.offerSideBar;
    },
    vehicleAcceptOffer(vehicle) {
      return this.$store.state.adminStore.vehicles.offersStore.vehiclesSold.vehicles.find(
        (v) => v.vehicleId === vehicle.id
      );
    },
    showVehicleOffers(vehicle) {
      this.$store.commit(SHOW_OFFER_SIDEBAR, false);
      this.$store.commit(SHOW_OFFERS_SIDEBAR, false);
      this.getVehicleOffers(vehicle);
      setTimeout(() => {
        this.$store.commit(SHOW_OFFER_SIDEBAR, true);
      }, 1000);
    },
    getVehicleOffers(vehicle) {
      this.$store
        .dispatch(GET_OFFERS_BY_VEHICLE, vehicle.vehicleId)
        .then(() => {
          // SET VEHICLE DATA TO SHOW IN SIDEBAR
          this.vehicle.isActiveOffer = this.isActiveOffer;
          this.$store.commit(SET_OFFER_SIDEBAR, this.vehicle);

          this.markNoticationAsRead();
        })

        .catch(() => {
          this.toastMessage(
            "The vehicle offers could not be obtained",
            "Warning",
            "warning"
          );
        });
    },
    changeOfferTab(vehicleId) {
      this.$emit("resetFilters");
      this.$store.commit(SET_VEHICLES_FILTERS, { fields: { vehicleId } });
      this.$emit("changeTabNumber");
    },
    editVehicle(vin) {
      this.$store.commit("SET_IS_SHOWING_VIN_INFORMATION_SIDEBAR", {
        status: true,
        data: vin,
      });
    },
    setUserHasOffer(offer) {
      this.userHasOffer = true;
      this.currentOffer = offer;
    },
    calculatedCost(vehicleCost, fx = null, vehicleCostUsd) {
      let cost = vehicleCost;
      if (fx) {
        if (vehicleCost === 0) {
          return cost;
        } else {
          return (cost + 2500) / fx;
        }
      }
      return vehicleCostUsd;
    },
    refreshVehicles() {
      this.refresh();
    },
    getVehiclePageUrl(vehicle) {
      return (
        "/vehicle/" +
        vehicle.vinNumber +
        "-" +
        vehicle.make +
        "-" +
        vehicle.model
      );
    },

    generateUrlToCopy(vehicle) {
      return (
        window.location.hostname +
        "/#" +
        "/vehicle/" +
        vehicle.vinNumber +
        "-" +
        vehicle.make +
        "-" +
        vehicle.model
      );
    },

    formatImagesFromSpace(vehicle) {
      this.isLoadingImagesFromSpace = true;
      let images = [];
      let baseUrl = `${this.BASE_URL}/${vehicle?.year}/${vehicle?.make}/${vehicle?.model2}/${vehicle?.color}`;
      let promises = [];
      IMAGES_PARTS.forEach((part) => {
        let img = new Image();
        let promise = new Promise((resolve) => {
          img.onload = () => {
            images.push({
              id: `${baseUrl}/${part}.jpg`,
              src: `${baseUrl}/${part}.jpg`,
              largeViewSrc: `${baseUrl}/${part}.jpg`,
              thumbnail: `${baseUrl}/${part}.jpg`,
            });
            resolve();
          };
          img.onerror = () => {
            images.push({
              id: "proleasing-logo.png",
              src: "proleasing-logo.png",
              largeViewSrc: "proleasing-logo.png",
              thumbnail: "proleasing-logo.png",
            });
            resolve();
          };
        });
        promises.push(promise);
        img.src = `${baseUrl}/${part}.jpg`;
      });

      Promise.all(promises).then(() => {
        return images;
        /*         this.isLoadingImagesFromSpace = false;
         */
      });
    },

    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.toastMessage("Link copied to clipboard", "Success", "success");
        },
        () => {
          this.toastMessage("Link could not be copied", "Warning", "warning");
        }
      );
    },

    checkIfImageIsBroken(url) {
      let img = new Image();
      img.src = url;
      img.onload = () => {
        return true;
      };
      img.onerror = () => {
        return false;
      };
    },
  },
};
</script>

<style></style>
