<template>
  <div>
    <b-navbar variant="primary">
      <b-nav class="w-100 d-lg-inline-flex" align="end">
        <b-row>
          <b-col cols="6" />
          <b-col cols="6">
            <b-pagination
              @change="changePage"
              :value="pagination.currentPage"
              :total-rows="pagination.total"
              :per-page="pagination.perPage"
              align="right"
            />
          </b-col>
        </b-row>
      </b-nav>
    </b-navbar>

    <b-row
      class="mt-3"
      :class="{ 'container-fluid': witdhResponsive !== '100%' }"
    >
      <b-col>
        <Search
          class="search sticky-top"
          :offersView="true"
          style="z-index: 999 !important"
        />
      </b-col>
      <b-col xl="9" md="9" sm="12">
        <b-tabs content-class="mt-3">
          <b-tab
            :title="$t('activeOffers')"
            @click="getVehiclesWithActiveOffers"
            active
          >
            <b-row>
              <b-col
                cols="12"
                v-if="vehicleWithOffersStatus === 'fetching'"
                class="mt-2"
              >
                <Skeleton />
              </b-col>

              <b-col
                v-else-if="vehicleWithOffers && vehicleWithOffers.length"
                class="mt-2"
                xl="4"
                md="4"
                sm="12"
                v-for="vehicle in vehicleWithOffers"
                :key="vehicle.id"
              >
                <VehicleCard :vehicle="vehicle" :viewOffers="true" />
              </b-col>
              <b-col v-else class="mt-2 col-lg-4 col-centered">
                <b>No records to show</b>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab :title="$t('expiredOffers')" @click="getExpiredOffers">
            <b-row>
              <b-col
                cols="12"
                v-if="vehicleWithOffersStatus === 'fetching'"
                class="mt-2"
              >
                <Skeleton />
              </b-col>

              <b-col
                v-else-if="vehicleWithOffers && vehicleWithOffers.length"
                class="mt-2"
                xl="4"
                md="4"
                sm="12"
                v-for="vehicle in vehicleWithOffers"
                :key="vehicle.id"
              >
                <VehicleCard
                  :vehicle="vehicle"
                  :viewOffers="true"
                  :isExpiredOffer="true"
                />
              </b-col>
              <b-col v-else class="mt-2 col-lg-4 col-centered">
                <b>No records to show</b>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
    <OfferSideBar />
  </div>
</template>

<script>
import { vehicleOffersMixins } from "./mixins";
import Skeleton from "./components/Skeleton";
import Search from "../list/Search";
import VehicleCard from "../list/VehicleCard";
import {
  GET_VEHICLES_WITH_OFFERS,
  SHOW_OFFER_SIDEBAR,
  SET_OFFER_SIDEBAR,
} from "./actions";
import {
  SET_VEHICLES_FILTERS,
  SET_VEHICLE_FILTER_ACTION,
} from "../list/actions";
import OfferSideBar from "./components/SideBar";

export default {
  mixins: [vehicleOffersMixins],
  components: { Skeleton, Search, VehicleCard, OfferSideBar },
  computed: {
    filterAction() {
      return this.$store.state.adminStore.vehicles.listStore.filterAction
        .action;
    },
    vehicleWithOffersStore() {
      return this.$store.state.adminStore.vehicles.offersStore
        .vehiclesWithOffers;
    },
    vehicleWithOffersStatus() {
      return this.vehicleWithOffersStore.status;
    },
    pagination() {
      return {
        currentPage: this.vehicleWithOffersStore?.data?.currentPage,
        total: this.vehicleWithOffersStore?.data?.total,
        perPage: this.vehicleWithOffersStore?.data?.perPage,
      };
    },
    vehicleWithOffers() {
      return this.vehicleWithOffersStore?.data?.data;
    },
  },
  methods: {
    getVehiclesWithOffers(withFilters) {
      this.$store.dispatch(GET_VEHICLES_WITH_OFFERS, withFilters);
    },
    changePage(page) {
      this.$store.commit(SET_VEHICLES_FILTERS, { fields: { page } });
      this.getOffers(true);
    },
    closeOfferSideBar() {
      this.$store.commit(SHOW_OFFER_SIDEBAR, false);
      this.$store.commit(SET_OFFER_SIDEBAR, null);
    },
    getExpiredOffers() {
      this.$store.commit(SET_VEHICLES_FILTERS, {
        reset: true,
        ...{ fields: { status: 0 } },
      });
      this.$store.commit(SET_VEHICLE_FILTER_ACTION, "GET_VEHICLES_WITH_OFFERS");
      this.getVehiclesWithOffers(true);
    },
    getVehiclesWithActiveOffers() {
      this.$store.commit(SET_VEHICLES_FILTERS, {
        reset: true,
        ...{ fields: { status: 1 } },
      });
      this.$store.commit(SET_VEHICLE_FILTER_ACTION, "GET_VEHICLES_WITH_OFFERS");
      this.getVehiclesWithOffers(true);
    },
  },
  beforeMount() {
    this.$store.commit(SET_VEHICLES_FILTERS, {
      reset: true,
      ...{ fields: { status: 1 } },
    });
    this.$store.commit(SET_VEHICLE_FILTER_ACTION, "GET_VEHICLES_WITH_OFFERS");
    this.getVehiclesWithOffers(true);
  },
  beforeDestroy() {
    this.$store.commit(SET_VEHICLE_FILTER_ACTION, null);
    this.closeOfferSideBar();
    this.$store.commit(SET_VEHICLES_FILTERS, { reset: true });
  },
};
</script>

<style lang="scss">
@import "./offers.scss";
</style>
