<template>
  <div>
    <b-row class="mx-0">
      <b-col xs="12" md="12" lg="10" class="pr-0 pl-0 mx-0">
        <!--        <vue-single-select
          ref="companySelect"
          :classes="{
            input: 'form-control',
            dropdown: 'dropdown p-0',
          }"
          v-model="selected"
          :value="selected"
          :options="companies"
          optionLabel="companyName"
        >
          <template slot="option" slot-scope="{ option }">
            <div class="">
              <span> {{ option.companyName }}</span>
            </div>
          </template>
        </vue-single-select> -->
        <div>
          <vue-multiselect
            v-model="selected"
            id="ajax"
            label="companyName"
            track-by="companyName"
            placeholder="Type to search"
            open-direction="bottom"
            :options="companies"
            :multiple="false"
            :searchable="true"
            :loading="isLoading"
            :internal-search="false"
            :clear-on-select="false"
            :close-on-select="false"
            :options-limit="300"
            :limit="3"
            :max-height="600"
            :show-no-results="true"
            :hide-selected="true"
            @search-change="getCompanies"
            :selectLabel="''"
          >
            <template slot="tag" slot-scope="{ option, remove }"
              ><span class="custom__tag"
                ><span>{{ option.name }}</span
                ><span class="custom__remove" @click="remove(option)"
                  >❌</span
                ></span
              ></template
            >
            <template slot="clear" slot-scope="props">
              <div
                class="multiselect__clear"
                v-if="selected.length"
                @mousedown.prevent.stop="clearAll(props.search)"
              ></div>
            </template>
            <span slot="noResult"
              >Oops! No elements found. Consider changing the search
              query.</span
            >
          </vue-multiselect>
        </div>
      </b-col>
      <b-col md="12" lg="2" class="px-1 mx-0 text-center">
        <b-icon
          @click="assignCompany()"
          icon="check-circle"
          font-scale="1.20"
          class="mr-1 mt-2 cursor-pointer"
          variant="success"
        >
        </b-icon>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { PUT_ASSIGN_COMPANY } from "./actions";
/* import VueSingleSelect from "vue-single-select";
 */
import { mapState } from "vuex";
export default {
  components: {
    /* VueSingleSelect */
  },
  props: ["user", "refreshData", "companyId"],
  computed: {
    ...mapState({
      companies: (state) =>
        state.adminStore.assignCompany.companiesToAssign.data || [],
    }),
  },

  data() {
    return {
      selected: "",
      isLoading: false,
    };
  },

  created() {},
  mounted() {
    this.selectDefaultCompany();
  },

  methods: {
    clearUserSelect() {
      this.selected = null;
    },
    assignCompany() {
      this.$store
        .dispatch(PUT_ASSIGN_COMPANY, {
          userId: this.user.id,
          companyId: this.selected.id,
        })
        .then(() => {
          this.$root.$bvToast.toast("Company assigned successfully", {
            title: "Success",
            variant: "success",
          });
          this.clearUserSelect();
          this.refreshData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selectDefaultCompany() {
      if (this.companyId) {
        this.selected = this.companies.find(
          (company) => company.id === this.companyId
        );
      }
    },
    getCompanies(search = "") {
      this.isLoading = true;
      if (
        search.length < 3 /* || search === "" || this.companies.length > 0 */
      ) {
        this.isLoading = false;
        return;
      }
      this.$store
        .dispatch("GET_COMPANIES_LIST", { search })
        .then(() => {})
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    },

    /*   onUserSelect(user) {
      this.$emit("user-select", user);
    }, */
  },
};
</script>

<style></style>
