<template>
  <b-form>
    <b-form-group label="Url">
      <b-form-input
        @change="setValue('url', $event)"
        :state="validateState('url')"
        v-model.trim="$v.form.url.$model"
        aria-describedby="input-url-live-feedback"
      />
      <b-form-invalid-feedback id="input-description-live-feedback">
        This is a required field.
      </b-form-invalid-feedback>
    </b-form-group>

    <div class="text-right mt-2">
      <b-button
        :disabled="$v.form.$invalid || saveConditionalReport === 'fetching'"
        variant="primary"
        @click="addConditionalReport()"
      >
        <b-spinner
          v-if="saveConditionalReport === 'fetching'"
          variant="white"
          small
        />
        <span v-else>{{ $t("submit") }}</span>
      </b-button>
    </div>
  </b-form>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import { superAdminMixin } from "../../mixins";
import { POST_VEHICLE_CONDITIONAL_REPORT } from "../actions";
export default {
  props: ["vehicle"],
  mixins: [superAdminMixin],
  data() {
    return {
      form: {
        url: null,
        vehicleId: null,
      },
    };
  },
  computed: {
    ...mapState({
      saveConditionalReport: (state) =>
        state.superAdminStore.componentStore.saveConditionalReport.status,
    }),
  },
  validations: {
    form: {
      url: {
        required,
      },
    },
  },
  methods: {
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form.url = null;
      this.form.vehicleId = null;
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    addConditionalReport() {
      if (this.$v.form.$invalid) return;
      this.form.vehicleId = this.vehicle.id;
      this.$store
        .dispatch(POST_VEHICLE_CONDITIONAL_REPORT, this.form)
        .then(() => {
          this.resetForm();
          this.getConditionalReports(true, this.vehicle);
          this.toastMessage("Condition report saved", "Success", "success");
          this.getVehiclesInfo();
        })
        .catch(() => {
          this.toastMessage(
            "Condition report could not saved",
            "Warning",
            "warning"
          );
        });
    },
  },
};
</script>

<style></style>
