export default [
  'front',
  'front-right',
  'back',
  'left',
  'interior',
  'interior2',
  'other-1',
  'other-2',
  'other-3',
  'other-4',
  'other-5',
  'other-6',
  'other-7',
  'other-8',
  'other-9',
  'other-10',
  'other-11',
  'other-12',
  'other-13',
  'other-14',
  'other-15',
]