export const GET_VEHICLES = "GET_VEHICLES";
export const GET_VEHICLES_SUCCESS = "GET_VEHICLES_SUCCESS";
export const GET_VEHICLES_ERROR = "GET_VEHICLES_ERROR";

export const GET_VEHICLE_FILTERS = "GET_VEHICLE_FILTERS";
export const GET_VEHICLE_FILTERS_SUCCESS = "GET_VEHICLE_FILTERS_SUCCESS";
export const GET_VEHICLE_FILTERS_ERROR = "GET_VEHICLE_FILTERS_ERROR";

export const SET_VEHICLES_FILTERS = "SET_VEHICLES_FILTERS";

export const SET_VEHICLE_FILTER_ACTION = "SET_VEHICLE_FILTER_ACTION";

export const CLEAR_VEHICLE_MODELS = "CLEAR_VEHICLE_MODELS";

export const GET_VEHICLE_MODELS = "GET_VEHICLE_MODELS";
export const GET_VEHICLE_MODELS_SUCCESS = "GET_VEHICLE_MODELS_SUCCESS";
export const GET_VEHICLE_MODELS_ERROR = "GET_VEHICLE_MODELS_ERROR";

export const CLEAR_VEHICLE_TRIMS = "CLEAR_VEHICLE_TRIMS";

export const GET_VEHICLE_TRIMS = "GET_VEHICLE_TRIMS";
export const GET_VEHICLE_TRIMS_SUCCESS = "GET_VEHICLE_TRIMS_SUCCESS";
export const GET_VEHICLE_TRIMS_ERROR = "GET_VEHICLE_TRIMS_ERROR";

export const MARK_NOTIFICATION_AS_READ = "MARK_NOTIFICATION_AS_READ";
export const MARK_NOTIFICATION_AS_READ_SUCCESS =
  "MARK_NOTIFICATION_AS_READ_SUCCESS";
export const MARK_NOTIFICATION_AS_READ_ERROR =
  "MARK_NOTIFICATION_AS_READ_ERROR";

export const POST_SYNC_VEHICLES = "POST_SYNC_VEHICLES";
export const POST_SYNC_VEHICLES_SUCCESS = "POST_SYNC_VEHICLES_SUCCESS";
export const POST_SYNC_VEHICLES_ERROR = "POST_SYNC_VEHICLES_ERROR";

export const GET_VEHICLES_EXCEL = "GET_VEHICLES_EXCEL";
export const GET_VEHICLES_EXCEL_SUCCESS = "GET_VEHICLES_EXCEL_SUCCESS";
export const GET_VEHICLES_EXCEL_ERROR = "GET_VEHICLES_EXCEL_ERROR";